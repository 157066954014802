export function getUserRegister() {
  const user: any = localStorage.getItem('user')
    ? localStorage.getItem('user')
    : null;
  return JSON.parse(user);
}

export function setUserRegister(user) {
  return localStorage.setItem('user', user);
}

export function getVisitInfo() {
  const visitInfo: any = localStorage.getItem('visitHomeInfo')
    ? localStorage.getItem('visitHomeInfo')
    : null;
  return JSON.parse(visitInfo);
}

export function setVisitInfo(visitInfo) {
  return localStorage.setItem('visitHomeInfo', visitInfo);
}

export function getUserCognito() {
  const user: any = localStorage.getItem('userData')
    ? localStorage.getItem('userData')
    : null;
  return JSON.parse(user);
}

export function setUserCognito(userData) {
  localStorage.removeItem('userData');
  return localStorage.setItem('userData', userData);
}

export function getUserCognitoVerified() {
  const user: any = localStorage.getItem('verifyUserInfo')
    ? localStorage.getItem('verifyUserInfo')
    : null;
  return JSON.parse(user);
}

export function setUserCognitoVerified(verifyUserInfo) {
  return localStorage.setItem('verifyUserInfo', verifyUserInfo);
}

export function getConnectPlaid() {
  return localStorage.getItem('plaid');
}

export function setConnectPlaid(plaid) {
  return localStorage.setItem('plaid', plaid);
}

export function getConnectPlaidReLink() {
  const data: any = localStorage.getItem('plaidRelink');
  return JSON.parse(data);
}

export function setConnectPlaidReLink(plaid) {
  return localStorage.setItem('plaidRelink', JSON.stringify(plaid));
}

export function getEmailLogin() {
  return localStorage.getItem('email');
}

export function setEmailLogin(email) {
  return localStorage.setItem('email', email);
}

export function setNearLocationData(data) {
  return localStorage.setItem('nearByData', JSON.stringify(data));
}

export function getNearLocationData() {
  const data: any = localStorage.getItem('nearByData');
  return JSON.parse(data);
}

export function setFilterLocationByRestaurantData(data) {
  return localStorage.setItem('nearRestaurantData', JSON.stringify(data));
}

export function getFilterLocationByRestaurantData() {
  const data: any = localStorage.getItem('nearRestaurantData');
  return JSON.parse(data);
}

export function getFilterLocationByGasData() {
  const data: any = localStorage.getItem('nearGasData');
  return JSON.parse(data);
}

export function setFilterLocationByGasData(data) {
  return localStorage.setItem('nearGasData', JSON.stringify(data));
}

export function setFilterLocationByGroceriesData(data) {
  return localStorage.setItem('nearGroceriesData', JSON.stringify(data));
}

export function getFilterLocationByGroceriesData() {
  const data: any = localStorage.getItem('nearGroceriesData');
  return JSON.parse(data);
}

export function setFilterLocationByCoffeeData(data) {
  return localStorage.setItem('nearCoffeeData', JSON.stringify(data));
}

export function getFilterLocationByCoffeeData() {
  const data: any = localStorage.getItem('nearCoffeeData');
  return JSON.parse(data);
}

export function setTimeFetchMap(data) {
  return localStorage.setItem('dateGetMap', JSON.stringify(data));
}
export function getTimeFetchMap() {
  const data: any = localStorage.getItem('dateGetMap');
  return JSON.parse(data);
}

export function setTimeShowError2Dashboard(data) {
  return localStorage.setItem('dateShowError2Dashboard', JSON.stringify(data));
}
export function getTimeShowError2Dashboard() {
  const data: any = localStorage.getItem('dateShowError2Dashboard');
  return JSON.parse(data);
}

export function setTimeShowError3Dashboard(data) {
  return localStorage.setItem('dateShowError3Dashboard', JSON.stringify(data));
}
export function getTimeShowError3Dashboard() {
  const data: any = localStorage.getItem('dateShowError3Dashboard');
  return JSON.parse(data);
}
export function setInfoDeepLink(data) {
  return localStorage.setItem('infoDeepLink', JSON.stringify(data));
}
export function getInfoDeepLink() {
  const data: any = localStorage.getItem('infoDeepLink');
  return data ? JSON.parse(data) : null;
}

export function setIsDeepLink(isDeepLink: boolean) {
  return localStorage.setItem('isDeepLink', JSON.stringify(isDeepLink));
}

export function getIsDeepLink() {
  const isDeepLink = localStorage.getItem('isDeepLink');
  return isDeepLink && JSON.parse(isDeepLink);
}

export function setDataTrackingScreen(data) {
  return localStorage.setItem('TRACKING_SCREEN', JSON.stringify(data));
}

export function getDataTrackingScreen() {
  const data = localStorage.getItem('TRACKING_SCREEN');
  return data && JSON.parse(data);
}

export function clearTrackingScreen() {
  return localStorage.setItem('TRACKING_SCREEN', JSON.stringify([]));
}
