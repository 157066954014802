import * as React from 'react';
import { formatMoneyValue } from 'utils/utils';
// import { iconAdd } from 'containers/Assets/images';

export function BankCardEarnedDetails(props) {
  const {
    data,
    subTitle,
    imgClassName = '',
    // onAdd
  } = props;
  return (
    <div className={`card-details col-md-12 d-flex `}>
      <div className="col-md-4 p-0 pr-3 img-card-grp img-card-grp-earned">
        <img
          src={data.accountImageUrl || null}
          alt="img-nav"
          width="75px"
          height="47px"
          className={imgClassName}
        />
        {/* <img
          className="img-earned-add"
          src={iconAdd}
          alt="Add"
          width="23"
          height="23"
          onClick={() => onAdd(data)}
        /> */}
      </div>
      <div className="col-md-8 row rw-card-grp-earned">
        <div className="mb-0 m-auto  row rw-card-grp-earned-spent-reward">
          <div className="p-0 spent-item">
            <div className="spent-title">Net Spent</div>
            <div className="spent-price nowrap">{`$${
              data?.totalSpent ? formatMoneyValue(data?.totalSpent, true) : 0
            }`}</div>
          </div>
          <div className="border-right" />
          <div className="p-0 rewards-item">
            <div className="rewards-title">{subTitle || 'Rewards'}</div>
            <div className="rewards-price nowrap">
              {!data?.notCalculateReward
                ? `$${
                    data?.totalRewards
                      ? formatMoneyValue(data?.totalRewards, true)
                      : 0
                  }`
                : '_ _'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
