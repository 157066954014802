export const logType = {
  // Signup Event
  page_signup_load: { subevent: 'page_signup_load', event: 'signup' },
  page_signup_exit: { subevent: 'page_signup_exit', event: 'signup' },
  page_signup_otp_load: { subevent: 'page_signup_otp_load', event: 'signup' },
  page_signup_otp_exit: { subevent: 'page_signup_otp_exit', event: 'signup' },
  clicked_signup_textfield_phone: {
    subevent: 'clicked_signup_textfield_phone',
    event: 'signup',
  },
  clicked_signup_textfield_phone_valid: {
    subevent: 'clicked_signup_textfield_phone_valid',
    event: 'signup',
  },
  clicked_signup_textfield_email: {
    subevent: 'clicked_signup_textfield_email',
    event: 'signup',
  },
  clicked_signup_textfield_email_valid: {
    subevent: 'clicked_signup_textfield_email_valid',
    event: 'signup',
  },
  clicked_signup_button_continue: {
    subevent: 'clicked_signup_button_continue',
    event: 'signup',
  },
  clicked_signup_link_TC: {
    subevent: 'clicked_signup_link_TC',
    event: 'signup',
  },
  clicked_signup_link_login: {
    subevent: 'clicked_signup_link_login',
    event: 'signup',
  },
  clicked_signup_link_tos: {
    subevent: 'clicked_signup_link_tos',
    event: 'signup',
  },
  clicked_signup_link_privacy: {
    subevent: 'clicked_signup_link_privacy',
    event: 'signup',
  },
  clicked_signup_link_back: {
    subevent: 'clicked_signup_link_back',
    event: 'signup',
  },
  clicked_signup_otp_link_resend: {
    subevent: 'clicked_signup_otp_link_resend',
    event: 'signup',
  },
  clicked_signup_otp_textfield_otp: {
    subevent: 'clicked_signup_otp_textfield_otp',
    event: 'signup',
  },
  clicked_signup_otp_result_otp_fail: {
    subevent: 'clicked_signup_otp_result_otp_fail',
    event: 'signup',
  },
  clicked_signup_otp_result_otp_success: {
    subevent: 'clicked_signup_otp_result_otp_success',
    event: 'signup',
  },
  page_signup_prvinfo_load: {
    subevent: 'page_signup_prvinfo_load',
    event: 'signup',
  },
  page_signup_prvinfo_exit: {
    subevent: 'page_signup_prvinfo_exit',
    event: 'signup',
  },
  clicked_signup_textfield_dob: {
    subevent: 'clicked_signup_textfield_dob',
    event: 'signup',
  },
  clicked_signup_textfield_ssn: {
    subevent: 'clicked_signup_textfield_ssn',
    event: 'signup',
  },
  clicked_signup_textfield_ssn_valid: {
    subevent: 'clicked_signup_textfield_ssn_valid',
    event: 'signup',
  },
  clicked_signup_button_confirmed: {
    subevent: 'clicked_signup_button_confirmed',
    event: 'signup',
  },
  clicked_signup_link_whatisthis: {
    subevent: 'clicked_signup_link_whatisthis',
    event: 'signup',
  },
  // Login Event
  page_login_load: {
    subevent: 'page_login_load',
    event: 'login',
  },
  page_login_exit: { subevent: 'page_login_exit', event: 'login' },
  page_login_otp_load: {
    subevent: 'page_login_otp_load',
    event: 'login',
  },

  page_login_otp_exit: { subevent: 'page_login_otp_exit', event: 'login' },
  clicked_login_link_signup: {
    subevent: 'clicked_signup_link_login',
    event: 'login',
  },
  clicked_login_button_login: {
    subevent: 'clicked_login_button_login',
    event: 'login',
  },

  clicked_login_button_login_success: {
    subevent: 'clicked_login_button_login_success',
    event: 'login',
  },
  clicked_login_button_login_accountnotfound: {
    subevent: 'clicked_login_button_login_accountnotfound',
    event: 'login',
  },
  clicked_login_link_back: {
    subevent: 'clicked_login_link_back',
    event: 'login',
  },
  clicked_login_otp_link_resend: {
    subevent: 'clicked_login_otp_link_resend',
    event: 'login',
  },
  clicked_login_otp_textfield_otp: {
    subevent: 'clicked_login_otp_textfield_otp',
    event: 'login',
  },
  clicked_login_otp_result_otp_success: {
    subevent: 'clicked_login_otp_result_otp_success',
    event: 'login',
  },
  clicked_login_otp_result_otp_fail: {
    subevent: 'clicked_login_otp_result_otp_fail',
    event: 'login',
  },

  page_appdownload_landing_load: {
    subevent: 'page_appdownload_landing_load',
    event: 'appdownload',
  },

  page_appdownload_landing_exit: {
    subevent: 'page_appdownload_landing_exit',
    event: 'appdownload',
  },

  clicked_appdownload_button_createaccount: {
    subevent: 'clicked_appdownload_button_createaccount',
    event: 'appdownload',
  },

  clicked_appdownload_button_login: {
    subevent: 'clicked_appdownload_login',
    event: 'appdownload',
  },

  page_appdownload_interstetial_load: {
    subevent: 'page_appdownload_interstetial_load',
    event: 'appdownload',
  },
  page_appdownload_interstetial_exit: {
    subevent: 'page_appdownload_interstetial_exit',
    event: 'appdownload',
  },
  clicked_appdownload_button_uthriveapp: {
    subevent: 'clicked_appdownload_button_uthriveapp',
    event: 'appdownload',
  },
  clicked_appdownload_button_uthriveweb: {
    subevent: 'clicked_appdownload_button_uthriveweb',
    event: 'appdownload',
  },
  clicked_appdownload_button_exit: {
    subevent: 'clicked_appdownload_button_exit',
    event: 'appdownload',
  },

  // Home Event - Splash screen
  page_account_splash_load: {
    subevent: 'page_account_splash_load',
    event: 'account_splash',
  },
  page_account_splash_exit: {
    subevent: 'page_account_splash_exit',
    event: 'account_splash',
  },
  page_account_splash_linkaccount_skip: {
    subevent: 'page_account_splash_linkaccount_skip',
    event: 'account_splash',
  },
  clicked_account_splash_button_linkaccount: {
    subevent: 'clicked_account_splash_button_linkaccount',
    event: 'account_splash',
  },
  clicked_account_splash_button_linkaccount_plaid_load: {
    subevent: 'clicked_account_splash_button_linkaccount_plaid_load',
    event: 'account_splash',
  },
  clicked_account_splash_button_linkaccount_plaid_exit: {
    subevent: 'clicked_account_splash_button_linkaccount_plaid_exit',
    event: 'account_splash',
  },
  clicked_account_splash_button_linkaccount_plaid_success: {
    subevent: 'clicked_account_splash_button_linkaccount_plaid_success',
    event: 'account_splash',
  },
  clicked_account_splash_result_success: {
    subevent: 'clicked_account_splash_result_success',
    event: 'account_splash',
  },

  page_account_splash_button_notifications: {
    subevent: 'page_account_splash_button_notifications',
    event: 'account_splash',
  },
  page_account_splash_button_notifications_allow: {
    subevent: 'page_account_splash_button_notifications_allow',
    event: 'account_splash',
  },
  page_account_splash_button_notifications_dontallow: {
    subevent: 'page_account_splash_button_notifications_dontallow',
    event: 'account_splash',
  },

  // Home Event - Account main
  page_account_main_load: {
    subevent: 'page_account_main_load',
    event: 'account_main',
  },
  page_account_main_exit: {
    subevent: 'page_account_main_exit',
    event: 'account_main',
  },
  clicked_account_main_tab_year: {
    subevent: 'clicked_account_main_tab_year',
    event: 'account_main',
  },
  clicked_account_main_tab_month: {
    subevent: 'clicked_account_main_tab_month',
    event: 'account_main',
  },
  clicked_account_main_tile_earned: {
    subevent: 'clicked_account_main_tile_earned',
    event: 'account_main',
  },
  clicked_account_main_tile_missed: {
    subevent: 'clicked_account_main_tile_missed',
    event: 'account_main',
  },
  clicked_account_main_tile_futurespend: {
    subevent: 'clicked_account_main_tile_futurespend',
    event: 'account_main',
  },
  clicked_account_main_tile_personalizedadvise: {
    subevent: 'clicked_account_main_tile_personalizedadvise',
    event: 'account_main',
  },
  clicked_account_main_info_rewards_summary: {
    subevent: 'clicked_account_main_info_rewards_summary',
    event: 'account_main',
  },
  // notification
  notification_link_account: {
    subevent: 'notification_link_account',
    event: 'notification',
  },

  // / Home Event - personal details interstetial
  page_interstetial_account_verification_load: {
    subevent: 'page_interstetial_account_verification_load',
    event: 'personal_details_interstetial',
  },

  page_interstetial_account_verification_exit: {
    subevent: 'page_interstetial_account_verification_exit',
    event: 'personal_details_interstetial',
  },

  clicked_interstetial_account_verification_textfield_firstname: {
    subevent: 'clicked_interstetial_account_verification_textfield_firstname',
    event: 'personal_details_interstetial',
  },
  clicked_interstetial_account_verification_textfield_lastname: {
    subevent: 'clicked_interstetial_account_verification_textfield_lastname',
    event: 'personal_details_interstetial',
  },
  clicked_interstetial_account_verification_textfield_address: {
    subevent: 'clicked_interstetial_account_verification_textfield_address',
    event: 'personal_details_interstetial',
  },
  clicked_interstetial_account_verification_button_confirm: {
    subevent: 'clicked_interstetial_account_verification_button_confirm',
    event: 'personal_details_interstetial',
  },

  //  Home Event - linkmore accounts while processing plaid data
  clicked_plaid_process_button_link_account: {
    subevent: 'clicked_plaid_process_button_link_account',
    event: 'linkmore_accounts_while_processing_plaid_data',
  },

  //  Home Event - Rewards earned
  page_earned_rewards_main_load: {
    subevent: 'page_earned_rewards_main_load',
    event: 'earned_rewards_main',
  },

  page_earned_rewards_main_exit: {
    subevent: 'page_earned_rewards_main_exit',
    event: 'earned_rewards_main',
  },

  clicked_earned_rewards_main_notification_addanothercard: {
    subevent: 'clicked_earned_rewards_main_notification_addanothercard',
    event: 'earned_rewards_main',
  },
  clicked_earned_rewards_main_notification_addanothercard_button_yes: {
    subevent:
      'clicked_earned_rewards_main_notification_addanothercard_button_yes',
    event: 'earned_rewards_main',
  },
  clicked_earned_rewards_main_notification_addanothercard_button_no: {
    subevent:
      'clicked_earned_rewards_main_notification_addanothercard_button_no',
    event: 'earned_rewards_main',
  },
  clicked_earned_rewards_main_tab_year: {
    subevent: 'clicked_earned_rewards_main_tab_year',
    event: 'earned_rewards_main',
  },
  clicked_earned_rewards_main_tab_month: {
    subevent: 'clicked_earned_rewards_main_tab_month',
    event: 'earned_rewards_main',
  },
  clicked_earned_rewards_main_tile: index => {
    return {
      subevent: `clicked_earned_rewards_main_tile_${index}`,
      event: 'earned_rewards_main',
    };
  },
  //  Home Event - Rewards earned details
  page_earned_rewards_transactions_load: {
    subevent: 'page_earned_rewards_transactions_load',
    event: 'eanred_rewards_transactions',
  },
  page_earned_rewards_transactions_exit: {
    subevent: 'page_earned_rewards_transactions_exit',
    event: 'eanred_rewards_transactions',
  },
  clicked_earned_rewards_transactions_tab_mostrecent: {
    subevent: 'clicked_earned_rewards_transactions_tab_mostrecent',
    event: 'eanred_rewards_transactions',
  },
  clicked_earned_rewards_transactions_tab_mostrearned: {
    subevent: 'clicked_earned_rewards_transactions_tab_mostrearned',
    event: 'eanred_rewards_transactions',
  },
  clicked_earned_rewards_transactions_scroll_up: {
    subevent: 'clicked_earned_rewards_transactions_scroll_up',
    event: 'eanred_rewards_transactions',
  },
  clicked_earned_rewards_transactions_scroll_down: {
    subevent: 'clicked_earned_rewards_transactions_scroll_down',
    event: 'eanred_rewards_transactions',
  },
  clicked_earned_rewards_main_info: {
    subevent: 'clicked_earned_rewards_main_info',
    event: 'eanred_rewards_transactions',
  },

  clicked_earned_rewards_transactions_tab_year: {
    subevent: 'clicked_earned_rewards_transactions_tab_year',
    event: 'eanred_rewards_transactions',
  },
  clicked_earned_rewards_transactions_tab_month: {
    subevent: 'clicked_earned_rewards_transactions_tab_month',
    event: 'eanred_rewards_transactions',
  },

  //Home Event - Rewards missed
  page_missed_rewards_main_load: {
    subevent: 'page_missed_rewards_main_load',
    event: 'missed_rewards_main',
  },
  page_missed_rewards_main_exit: {
    subevent: 'page_missed_rewards_main_exit',
    event: 'missed_rewards_main',
  },
  clicked_missed_rewards_main_notification_addanothercard: {
    subevent: 'clicked_missed_rewards_main_notification_addanothercard',
    event: 'missed_rewards_main',
  },
  clicked_missed_rewards_main_notification_addanothercard_button_yes: {
    subevent:
      'clicked_missed_rewards_main_notification_addanothercard_button_yes',
    event: 'missed_rewards_main',
  },
  clicked_missed_rewards_main_notification_addanothercard_button_no: {
    subevent:
      'clicked_missed_rewards_main_notification_addanothercard_button_no',
    event: 'missed_rewards_main',
  },
  clicked_missed_rewards_main_tab_year: {
    subevent: 'clicked_missed_rewards_main_tab_year',
    event: 'missed_rewards_main',
  },
  clicked_missed_rewards_main_tab_month: {
    subevent: 'clicked_missed_rewards_main_tab_month',
    event: 'missed_rewards_main',
  },
  clicked_missed_rewards_main_tile: index => {
    return {
      subevent: `clicked_missed_rewards_main_tile_${index}`,
      event: 'earned_rewards_main',
    };
  },
  clicked_missed_rewards_main_info: {
    subevent: 'clicked_missed_rewards_main_info',
    event: 'missed_rewards_main',
  },
  //  Home Event - Rewards missed details transactions
  page_missed_rewards_transactions_load: {
    subevent: 'page_missed_rewards_transactions_load',
    event: 'missed_rewards_transactions',
  },
  page_missed_rewards_transactions_exit: {
    subevent: 'page_missed_rewards_transactions_exit',
    event: 'missed_rewards_transactions',
  },
  clicked_missed_rewards_transactions_tab_mostrecent: {
    subevent: 'clicked_missed_rewards_transactions_tab_mostrecent',
    event: 'missed_rewards_transactions',
  },
  clicked_missed_rewards_transactions_tab_mostrearned: {
    subevent: 'clicked_missed_rewards_transactions_tab_mostrearned',
    event: 'missed_rewards_transactions',
  },
  clicked_missed_rewards_transactions_tab_year: {
    subevent: 'clicked_missed_rewards_transactions_tab_year',
    event: 'missed_rewards_transactions',
  },
  clicked_missed_rewards_transactions_tab_month: {
    subevent: 'clicked_missed_rewards_transactions_tab_month',
    event: 'missed_rewards_transactions',
  },
  clicked_missed_rewards_transactions_scroll_up: {
    subevent: 'clicked_missed_rewards_transactions_scroll_up',
    event: 'missed_rewards_transactions',
  },
  clicked_missed_rewards_transactions_scroll_down: {
    subevent: 'clicked_missed_rewards_transactions_scroll_down',
    event: 'missed_rewards_transactions',
  },
  clicked_missed_rewards_transactions_tile: index => {
    return {
      subevent: `clicked_missed_rewards_transactions_tile_${index}`,
      event: 'missed_rewards_transactions',
    };
  },
  clicked_missed_rewards_transactions_reco_card: {
    subevent: 'clicked_missed_rewards_transactions_reco_card',
    event: 'missed_rewards_transactions',
  },

  //  Home Event - Rewards missed details
  page_interstetial_missed_rewards_transactions_load: {
    subevent: 'page_interstetial_missed_rewards_transactions_load',
    event: 'missed_rewards_details',
  },
  page_interstetial_missed_rewards_transactions_exit: {
    subevent: 'page_interstetial_missed_rewards_transactions_exit',
    event: 'missed_rewards_details',
  },
  page_interstetial_missed_rewards_transactions_button_learnmore: {
    subevent: 'page_interstetial_missed_rewards_transactions_button_learnmore',
    event: 'missed_rewards_details',
  },

  // recommended cards
  page_recommended_card_exit: {
    subevent: 'page_recommended_card_exit',
    event: 'recommended_cards',
  },
  page_recommended_card_load: {
    subevent: 'page_recommended_card_load',
    event: 'recommended_cards',
  },
  clicked_recommended_card_link_allbenefits: {
    subevent: 'clicked_recommended_card_link_allbenefits',
    event: 'recommended_cards',
  },
  clicked_recommended_card_button_learnmore_apply: {
    subevent: 'clicked_recommended_card_button_learnmore_apply',
    event: 'recommended_cards',
  },
  clicked_recommended_card_info_total_earned: {
    subevent: 'clicked_recommended_card_info_total_earned',
    event: 'recommended_cards',
  },
  clicked_recommended_card_learnmore_leavinguthrive_continue: {
    subevent: 'clicked_recommended_card_learnmore_leavinguthrive_continue',
    event: 'recommended_cards',
  },
  clicked_recommended_card_learnmore_leavinguthrive_exit: {
    subevent: 'clicked_recommended_card_learnmore_leavinguthrive_exit',
    event: 'recommended_cards',
  },
  page_personalized_advise_load: {
    subevent: 'page_personalized_advise_load',
    event: 'personalized_advise',
  },
  page_personalized_advise_exit: {
    subevent: 'page_personalized_advise_exit',
    event: 'personalized_advise',
  },
  clicked_personalized_advise_notification_addanothercard: {
    subevent: 'clicked_personalized_advise_notification_addanothercard',
    event: 'personalized_advise',
  },
  clicked_personalized_advise_link_expandall: {
    subevent: 'clicked_personalized_advise_link_expandall',
    event: 'personalized_advise',
  },
  clicked_personalized_advise_link_collapseall: {
    subevent: 'clicked_personalized_advise_link_collapseall',
    event: 'personalized_advise',
  },
  clicked_personalized_advise_link_bestcard_learnmore: {
    subevent: 'clicked_personalized_advise_link_bestcard_learnmore',
    event: 'personalized_advise',
  },
  clicked_personalized_advise_tile_recommededcard: {
    subevent: 'clicked_personalized_advise_tile_recommededcard',
    event: 'personalized_advise',
  },
  clicked_personalized_advise_link_customrecommendation: {
    subevent: 'clicked_personalized_advise_link_customrecommendation',
    event: 'personalized_advise',
  },

  // Your cards
  page_your_cards_exit: {
    subevent: 'page_your_cards_exit',
    event: 'your_cards',
  },
  page_your_cards_load: {
    subevent: 'page_your_cards_load',
    event: 'your_cards',
  },
  clicked_your_cards_button_back: {
    subevent: 'clicked_your_cards_button_back',
    event: 'your_cards',
  },
  clicked_your_cards_tile: index => {
    return {
      subevent: `clicked_your_cards_tile_${index}`,
      event: 'missed_rewards_transactions',
    };
  },

  // custom_recommendations
  page_custom_recommendations_load: {
    subevent: 'page_custom_recommendations_load',
    event: 'custom_recommendations',
  },
  page_custom_recommendations_exit: {
    subevent: 'page_custom_recommendations_exit',
    event: 'custom_recommendations',
  },
  clicked_custom_recommendations_link_increase: {
    subevent: 'clicked_custom_recommendations_link_increase',
    event: 'custom_recommendations',
  },
  clicked_custom_recommendations_link_decrease: {
    subevent: 'clicked_custom_recommendations_link_decrease',
    event: 'custom_recommendations',
  },
  // yourcards_expand_click: {
  //   subevent: 'yourcards_expand_click',
  //   event: 'personalized_advise',
  // },
  // yourcards_collapse_click: {
  //   subevent: 'yourcards_collapse_click',
  //   event: 'personalized_advise',
  // },
  // recommended_scroll: {
  //   subevent: 'recommended_scroll',
  //   event: 'personalized_advise',
  // },
  // recommended_expand_click: {
  //   subevent: 'recommended_expand_click',
  //   event: 'personalized_advise',
  // },
  // recommended_collapse_click: {
  //   subevent: 'recommended_collapse_click',
  //   event: 'personalized_advise',
  // },
  // recommended_cards_created: {
  //   subevent: 'recommended_cards_created',
  //   event: 'recommended_cards',
  // },
  // recommended_cards_exited: {
  //   subevent: 'recommended_cards_exited',
  //   event: 'recommended_cards',
  // },
  // all_benefits_click: {
  //   subevent: 'all_benefits_click',
  //   event: 'recommended_cards',
  // },
  // learnmore_apply_click: {
  //   subevent: 'learnmore_apply_click',
  //   event: 'recommended_cards',
  // },
  // total_earned_click: {
  //   subevent: 'total_earned_click',
  //   event: 'recommended_cards',
  // },
  // chances_approval_click: {
  //   subevent: 'chances_approval_click',
  //   event: 'recommended_cards',
  // },
  // all_benefits_created: {
  //   subevent: 'all_benefits_created',
  //   event: 'all_benefits',
  // },
  // all_benefits_exited: {
  //   subevent: 'all_benefits_exited',
  //   event: 'all_benefits',
  // },
  // all_benefits_continue: {
  //   subevent: 'all_benefits_continue',
  //   event: 'all_benefits',
  // },
  // leaving_uthrive_click: {
  //   subevent: 'leaving_uthrive_click',
  //   event: 'all_benefits',
  // },
  // yourcards_created: {
  //   subevent: 'yourcards_created',
  //   event: 'yourcards',
  // },
  // yourcards_exited: {
  //   subevent: 'yourcards_exited',
  //   event: 'yourcards',
  // },
  // custom_recommendation_created: {
  //   subevent: 'custom_recommendation_created',
  //   event: 'custom_recommendations',
  // },
  // custom_recommendation_exited: {
  //   subevent: 'custom_recommendation_exited',
  //   event: 'custom_recommendations',
  // },
  // custom_recommendation_spendchange_click: {
  //   subevent: 'custom_recommendation_spendchange_click',
  //   event: 'custom_recommendations',
  // },

  // processing data
  page_processing_info_analyzing_spendinghabits: {
    subevent: 'page_processing_info_analyzing_spendinghabits',
    event: 'processing_data',
  },
  page_processing_info_updating_rewards: {
    subevent: 'page_processing_info_updating_rewards',
    event: 'processing_data',
  },
  page_processing_info_creating_personalized_advise: {
    subevent: 'page_processing_info_creating_personalized_advise',
    event: 'processing_data',
  },
  page_processing_info_almostthere: {
    subevent: 'page_processing_info_almostthere',
    event: 'processing_data',
  },
  page_processing_info_grabbingallinfo: {
    subevent: 'page_processing_info_grabbingallinfo',
    event: 'processing_data',
  },

  // my account
  page_myaccount_load: {
    subevent: 'page_myaccount_load',
    event: 'my_account',
  },
  page_myaccount_exit: {
    subevent: 'page_myaccount_exit',
    event: 'my_account',
  },
  clicked_myaccount_list_linkedaccounts: {
    subevent: 'clicked_myaccount_list_linkedaccounts',
    event: 'my_account',
  },
  clicked_myaccount_list_notifications: {
    subevent: 'clicked_myaccount_list_notifications',
    event: 'my_account',
  },
  clicked_myaccount_list_referafriend: {
    subevent: 'clicked_myaccount_list_referafriend',
    event: 'my_account',
  },
  clicked_myaccount_list_profile: {
    subevent: 'clicked_myaccount_list_profile',
    event: 'my_account',
  },
  clicked_myaccount_list_privacy_security: {
    subevent: `clicked_myaccount_list_privacy&security`,
    event: 'my_account',
  },
  clicked_myaccount_list_help_legal: {
    subevent: `clicked_myaccount_list_help&legal`,
    event: 'my_account',
  },
  clicked_myaccount_list_contactus: {
    subevent: `clicked_myaccount_list_contactus`,
    event: 'my_account',
  },
  clicked_myaccount_list_signout: {
    subevent: `clicked_myaccount_list_signout`,
    event: 'my_account',
  },
  page_linkedaccounts_load: {
    subevent: 'page_linkedaccounts_load',
    event: 'linked_accounts',
  },
  page_linkedaccounts_exit: {
    subevent: 'page_linkedaccounts_exit',
    event: 'linked_accounts',
  },
  clicked_myaccount_list_linkedaccounts_update: {
    subevent: 'clicked_myaccount_list_linkedaccounts_update',
    event: 'my_account',
  },
  clicked_myaccount_list_linkedaccounts_validate: {
    subevent: 'clicked_myaccount_list_linkedaccounts_validate',
    event: 'my_account',
  },
  clicked_myaccount_list_linkedaccounts_unlink: {
    subevent: 'clicked_myaccount_list_linkedaccounts_unlink',
    event: 'my_account',
  },
  clicked_myaccount_list_linkedaccounts_relink: {
    subevent: 'clicked_myaccount_list_linkedaccounts_relink',
    event: 'my_account',
  },

  // my account
  page_profile_load: {
    subevent: 'page_profile_load',
    event: 'profile',
  },
  page_profile_exit: {
    subevent: 'page_profile_exit',
    event: 'profile',
  },
  clicked_profile_link_edit: {
    subevent: 'clicked_profile_link_edit',
    event: 'profile',
  },
  clicked_profile_link_delete: {
    subevent: 'clicked_profile_link_delete',
    event: 'profile',
  },
  clicked_profile_link_cancel: {
    subevent: 'clicked_profile_link_cancel',
    event: 'profile',
  },
  clicked_profile_button_confirm: {
    subevent: 'clicked_profile_button_confirm',
    event: 'profile',
  },
  clicked_profile_info_preferredname: {
    subevent: 'clicked_profile_info_preferredname',
    event: 'profile',
  },

  // Invite friends
  page_invite_load: {
    subevent: 'page_invite_load',
    event: 'invite_friends',
  },
  page_invite_exit: {
    subevent: 'page_invite_exit',
    event: 'invite_friends',
  },
  page_invite_button_invite: {
    subevent: 'page_invite_button_invite',
    event: 'invite_friends',
  },
  page_invite_refer_load: {
    subevent: 'page_invite_refer_load',
    event: 'invite_friends',
  },
  page_invite_refer_exit: {
    subevent: 'page_invite_refer_exit',
    event: 'invite_friends',
  },
  page_invite_refer_button_invite: {
    subevent: 'page_invite_refer_button_invite',
    event: 'invite_friends',
  },
  page_invite_refer_button_invite_app: appName => {
    return {
      subevent: `page_invite_refer_button_invite_${appName}`,
      event: 'invite_friends',
    };
  },

  page_privacysecurity_load: {
    subevent: 'page_privacysecurity_load',
    event: 'privacy_and_security',
  },

  page_privacysecurity_exit: {
    subevent: 'page_privacysecurity_exit',
    event: 'privacy_and_security',
  },

  page_interstetial_card_load: {
    subevent: 'page_interstetial_card_load',
    event: 'card_specific_interstetial',
  },
  page_interstetial_card_exit: {
    subevent: 'page_interstetial_card_exit',
    event: 'card_specific_interstetial',
  },
  clicked_interstetial_card_radiobutton_yes: {
    subevent: 'clicked_interstetial_card_radiobutton_yes',
    event: 'card_specific_interstetial',
  },
  clicked_interstetial_card_radiobutton_no: {
    subevent: 'clicked_interstetial_card_radiobutton_no',
    event: 'card_specific_interstetial',
  },
  clicked_interstetial_card_button_confirm: {
    subevent: 'clicked_interstetial_card_button_confirm',
    event: 'card_specific_interstetial',
  },
  clicked_interstetial_card_link_skip: {
    subevent: 'clicked_interstetial_card_link_skip',
    event: 'card_specific_interstetial',
  },

  page_viewplacesnearyou_load: {
    subevent: 'page_viewplacesnearyou_load',
    event: 'stores_near_you',
  },
  page_viewplacesnearyou_exit: {
    subevent: 'page_viewplacesnearyou_exit',
    event: 'stores_near_you',
  },
  clicked_viewplacesnearyou_storeonmap: {
    subevent: 'clicked_viewplacesnearyou_storeonmap',
    event: 'stores_near_you',
  },
  clicked_viewplacesnearyou_modal_topcard: {
    subevent: 'clicked_viewplacesnearyou_modal_topcard',
    event: 'stores_near_you',
  },
  clicked_viewplacesnearyou_modal_topcard_exit: {
    subevent: 'clicked_viewplacesnearyou_modal_topcard_exit',
    event: 'stores_near_you',
  },
  clicked_viewplacesnearyou_button_next: {
    subevent: 'clicked_viewplacesnearyou_button_next',
    event: 'stores_near_you',
  },
  clicked_viewplacesnearyou_text_amount_enter: {
    subevent: 'clicked_viewplacesnearyou_text_amount_enter',
    event: 'stores_near_you',
  },
  clicked_viewplacesnearyou_text_amount_exit: {
    subevent: 'clicked_viewplacesnearyou_text_amount_exit',
    event: 'stores_near_you',
  },

  clicked_plaid_process_button_link_moreaccount: {
    subevent: 'clicked_plaid_process_button_link_moreaccount',
    event: 'linkmore_accounts_while_processing_plaid_data',
  },

  // help & legal
  page_helplegal_load: {
    subevent: 'page_helplegal_load',
    event: 'help&legal',
  },
  page_helplegal_exit: {
    subevent: 'page_helplegal_exit',
    event: 'help&legal',
  },
  clicked_helplegal_list_customercare: {
    subevent: 'clicked_helplegal_list_customercare',
    event: 'help&legal',
  },
  clicked_helplegal_list_acknowledgement: {
    subevent: 'clicked_helplegal_list_acknowledgement',
    event: 'help&legal',
  },
  clicked_helplegal_list_termsofuse: {
    subevent: 'clicked_helplegal_list_termsofuse',
    event: 'help&legal',
  },
  clicked_helplegal_list_privacy: {
    subevent: 'clicked_helplegal_list_privacy',
    event: 'help&legal',
  },

  // estimate rewards
  page_estimate_rewards_load: {
    subevent: 'page_estimate_rewards_load',
    event: 'estimate_rewards',
  },
  page_estimate_rewards_exit: {
    subevent: 'page_estimate_rewards_exit',
    event: 'estimate_rewards',
  },
  clicked_estimate_rewards_textfield_search: {
    subevent: 'clicked_estimate_rewards_textfield_search',
    event: 'estimate_rewards',
  },
  clicked_estimate_rewards_link_viewplacesnearyou: {
    subevent: 'clicked_estimate_rewards_link_viewplacesnearyou',
    event: 'estimate_rewards',
  },
  clicked_estimate_rewards_button_next: {
    subevent: 'clicked_estimate_rewards_button_next',
    event: 'estimate_rewards',
  },
  clicked_estimate_rewards_text_comparerewards_amount: {
    subevent: 'clicked_estimate_rewards_text_comparerewards_amount',
    event: 'estimate_rewards',
  },
  clicked_estimate_rewards_text_comparerewards_amount_exit: {
    subevent: 'clicked_estimate_rewards_text_comparerewards_amount_exit',
    event: 'estimate_rewards',
  },
  clicked_estimate_rewards_icon: appName => {
    return {
      subevent: `clicked_estimate_rewards_icon_${appName}`,
      event: 'invite_friends',
    };
  },
  //DeleteAccount
  clicked_deleteaccount_feedback: {
    event: ' delete_account_feedback',
    subevent: 'clicked_deleteaccount_feedback',
  },
  page_deleteaccount_feedback_load: {
    event: ' delete_account_feedback',
    subevent: 'page_deleteaccount_feedback_load',
  },
  clicked_deleteaccount_feedback_button_continue: {
    event: ' delete_account_feedback',
    subevent: 'clicked_deleteaccount_feedback_button_continue',
  },
  page_deleteaccount_feedback_exit: {
    event: ' delete_account_feedback',
    subevent: 'page_deleteaccount_feedback_exit',
  },
  page_deleteaccount_disablenotification_load: {
    event: 'delete_account_disable_notification',
    subevent: 'page_deleteaccount_disablenotification_load',
  },
  clicked_deleteaccount_disablenotification_button_notificationsetting: {
    event: 'delete_account_disable_notification',
    subevent:
      'clicked_deleteaccount_disablenotification_button_notificationsetting',
  },
  clicked_deleteaccount_disablenotification_button_continue: {
    event: 'delete_account_disable_notification',
    subevent: 'clicked_deleteaccount_disablenotification_button_continue',
  },
  page_deleteaccount_disablenotification_exit: {
    event: 'delete_account_disable_notification',
    subevent: 'page_deleteaccount_disablenotification_exit',
  },
  page_deleteaccount_otp_load: {
    event: 'delete_account_otp',
    subevent: 'page_deleteaccount_otp_load',
  },
  clicked_deleteaccount_otp_link_resend: {
    event: 'delete_account_otp',
    subevent: 'clicked_deleteaccount_otp_link_resend',
  },
  clicked_deleteaccount_otp_result_success: {
    event: 'delete_account_otp',
    subevent: 'clicked_deleteaccount_otp_result_success',
  },
  clicked_deleteaccount_otp_result_fail: {
    event: 'delete_account_otp',
    subevent: 'clicked_deleteaccount_otp_result_fail',
  },
  page_deleteaccount_otp_exit: {
    event: 'delete_account_otp',
    subevent: 'page_deleteaccount_otp_exit',
  },
  page_deleteaccount_submitted_load: {
    event: 'delete_account_submitted',
    subevent: 'page_deleteaccount_submitted_load',
  },
  page_deleteaccount_submitted_exit: {
    event: 'delete_account_submitted',
    subevent: 'page_deleteaccount_submitted_exit',
  },

  // web landing page
  page_landingpage_load: referral_code => ({
    event: 'webpage_landing',
    subevent: 'page_landingpage_load',
    referral_code,
  }),
  page_landingpage_exit: referral_code => ({
    event: 'webpage_landing',
    subevent: 'page_landingpage_exit',
    referral_code,
  }),
  // landing page journey
  page_customlandingpage_load_aws: referral_code => ({
    event: 'webpage_conversion_aws',
    subevent: 'page_customlandingpage_load_aws',
    referral_code,
  }),
  page_customlandingpage_exit_aws: referral_code => ({
    event: 'webpage_conversion_aws',
    subevent: 'page_customlandingpage_exit_aws',
    referral_code,
  }),
  clicked_button_viewresults_aws: referral_code => ({
    event: 'webpage_conversion_aws',
    subevent: 'clicked_button_viewresults_aws',
    referral_code,
  }),
  clicked_button_signup_aws: referral_code => ({
    event: 'webpage_conversion_aws',
    subevent: 'clicked_button_signup_aws',
    referral_code,
  }),
  clicked_button_login_aws: referral_code => ({
    event: 'webpage_conversion_aws',
    subevent: 'clicked_button_login_aws',
    referral_code,
  }),
  clicked_button_uthrivelogo_aws: referral_code => ({
    event: 'webpage_conversion_aws',
    subevent: 'clicked_button_uthrivelogo_aws',
    referral_code,
  }),
  clicked_link_downloaduthrive_aws: referral_code => ({
    event: 'webpage_conversion_aws',
    subevent: 'clicked_link_downloaduthrive_aws',
    referral_code,
  }),
  clicked_link_signup_aws: referral_code => ({
    event: 'webpage_conversion_aws',
    subevent: 'clicked_link_signup_aws',
    referral_code,
  }),
  clicked_button_earnmorerewards_aws: referral_code => ({
    event: 'webpage_conversion_aws',
    subevent: 'clicked_button_earnmorerewards_aws',
    referral_code,
  }),
};

export const logTypeAirbrigde = {
  page_appdownload_landing_load: {
    subevent: 'page_appdownload_landing_load',
    event: 'App landing page load',
  },
  clicked_appdownload_button_createaccount: {
    subevent: 'clicked_appdownload_button_createaccount',
    event: 'Click on create account',
  },
  clicked_signup_button_continue: {
    subevent: 'clicked_signup_button_continue',
    event: 'Email submission',
  },
  clicked_signup_button_confirmed: {
    subevent: 'clicked_signup_button_confirmed',
    event: 'SSN submitted',
  },
  clicked_account_splash_button_linkaccount_plaid_success: {
    subevent: 'clicked_account_splash_button_linkaccount_plaid_success',
    event: 'Account linked',
  },
  page_account_main_load: {
    subevent: 'page_account_main_load',
    event: 'Main Home Page',
  },
};
