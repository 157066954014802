import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, {
  // geocodeByAddress,
  // getLatLng,
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import { loggerHelper } from 'utils/utils';

const PlacesAutocompleteGroup = ({
  wrapperClass,
  label,
  textColor,
  onChange,
  name,
  isValidWithRedBorder,
  value,
  onBlur,
  logger,
  ...rest
}: any) => {
  const [address, setAddress] = useState('');
  const [loaded, setLoaded] = useState(false);

  const loadGMaps = callback => {
    const existingScript = document.getElementById('googleMaps');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
      script.id = 'googleMaps';
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };

  const handleSelect = (address, placeId) => {
    setAddress(address);
    // geocodeByAddress(address)
    //   .then(results => getLatLng(results[0]))
    //   .then(latLng => console.log('latLng', latLng))
    //   .catch(error => console.error('Error', error));

    geocodeByPlaceId(placeId)
      .then(results => {
        if (onChange) onChange({ name, value: results });
      })
      .catch(error => console.error('Error', error));
  };

  const handleRenderStreetAddress = address => {
    const addressList = address?.length ? address.split(',') : [];
    return addressList[0] || '';
  };

  useEffect(() => {
    loadGMaps(() => {
      setLoaded(true);
    });
  }, []);
  if (!loaded) return <div />;
  return (
    <div className={`form-group m-0 p-0 ${wrapperClass || ''}`}>
      <PlacesAutocomplete
        value={handleRenderStreetAddress(address)}
        onChange={address => setAddress(address)}
        onSelect={handleSelect}
        searchOptions={{
          types: ['address'],
          componentRestrictions: { country: 'us' },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div>
              <input
                {...getInputProps({
                  placeholder: '',
                  className: `location-search-input form-control ${
                    textColor || ''
                  } ${isValidWithRedBorder ? 'is-red-bottom-boder' : ''}`,
                })}
                onBlur={() => {
                  if (onBlur) onBlur();
                  if (logger) loggerHelper(logger);
                }}
                {...rest}
              />
              {label && (
                <label
                  className={`small-font-size ${
                    isValidWithRedBorder ? 'is-red-color' : ''
                  }`}
                  htmlFor="label"
                >
                  {label || ''}
                </label>
              )}
              {suggestions.length > 0 && (
                <div
                  className="autocomplete-dropdown-container"
                  style={{ marginBottom: 20 }}
                >
                  <div className="css-4ljt47-MenuList">
                    {loading && (
                      <div className="css-1n7v3ny-option text-center">
                        Loading...
                      </div>
                    )}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active css-1n7v3ny-option item-location'
                        : 'suggestion-item css-1n7v3ny-option item-location';
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={`key-asd${index}`}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
    </div>
  );
};

export default PlacesAutocompleteGroup;
