import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { previousIco, creditCard } from 'containers/Assets/images';
import GoogleMap from 'components/common/Map';
import { startsWith } from 'lodash';
import { HomeNavbar, Footer } from 'components';
import {
  getCurrentLocation,
  getMinuteFromTwoDate,
  loggerHelper,
} from 'utils/utils';
import { MAPCATEGORIES } from 'utils/constants';
import { logType } from 'types/logTypes';
import {
  getNearByGoogle,
  searchTextByGoogle,
  selectGooglePlace,
} from 'api/google';
import {
  setNearLocationData,
  getNearLocationData,
  setTimeFetchMap,
  getTimeFetchMap,
  getFilterLocationByCoffeeData,
  setFilterLocationByCoffeeData,
  getFilterLocationByGasData,
  setFilterLocationByGasData,
  getFilterLocationByGroceriesData,
  setFilterLocationByGroceriesData,
  getFilterLocationByRestaurantData,
  setFilterLocationByRestaurantData,
} from 'utils/localStorages';
import { getUserRegister } from 'utils/localStorages';
import { ModalEstimateRewardByPlace } from 'components/EstimateReward';

const saveCacheCategory = (type, data) => {
  switch (type) {
    case 'restaurants': {
      setFilterLocationByRestaurantData(data);
      return;
    }
    case 'gas': {
      setFilterLocationByGasData(data);
      return;
    }
    case 'groceries': {
      setFilterLocationByGroceriesData(data);
      return;
    }
    case 'coffee': {
      setFilterLocationByCoffeeData(data);
      return;
    }
    default: {
      setFilterLocationByRestaurantData(data);
      return;
    }
  }
};

// const apiKey = process.env.REACT_APP_GOOGLE_MAP_NEAR_API_KEY;
export default function NearLocation(props: any) {
  const history = useHistory();
  const cacheLocation = getNearLocationData();
  const cacheNearRestaurant = getFilterLocationByRestaurantData();
  const cacheNearGas = getFilterLocationByGasData();
  const cacheNearGroceries = getFilterLocationByGroceriesData();
  const cacheNearCoffee = getFilterLocationByCoffeeData();
  const [currentLocation, setCurrentLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [isOpenEstimateModal, setOpenModalEstimate] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<any>(null);
  const [activeCategory, setActiveCategory] = useState<any>(null);
  const [listMarker, setListMarker] = useState(cacheLocation);
  const user = getUserRegister() || {};

  const setLocation = position => {
    setCurrentLocation({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
  };

  const checkExistCachegory = type => {
    switch (type) {
      case 'restaurants': {
        const timePass = getMinuteFromTwoDate(
          new Date(),
          new Date(cacheNearRestaurant?.date),
        );

        const isValid =
          !!cacheNearRestaurant?.results &&
          !!cacheNearRestaurant?.date &&
          timePass <= 15;

        if (isValid) {
          setListMarker(cacheNearRestaurant);
        }
        return isValid;
      }
      case 'gas': {
        const timePass = getMinuteFromTwoDate(
          new Date(),
          new Date(cacheNearGas?.date),
        );
        const isValid =
          !!cacheNearGas?.results && !!cacheNearGas?.date && timePass <= 15;
        if (isValid) {
          setListMarker(cacheNearGas);
        }
        return isValid;
      }
      case 'groceries': {
        const timePass = getMinuteFromTwoDate(
          new Date(),
          new Date(cacheNearGroceries?.date),
        );
        const isValid =
          !!cacheNearGroceries?.results &&
          !!cacheNearGroceries?.date &&
          timePass <= 15;
        if (isValid) {
          setListMarker(cacheNearGroceries);
        }
        return isValid;
      }
      case 'coffee': {
        const timePass = getMinuteFromTwoDate(
          new Date(),
          new Date(cacheNearCoffee?.date),
        );
        const isValid =
          !!cacheNearCoffee?.results &&
          !!cacheNearCoffee?.date &&
          timePass <= 15;
        if (isValid) {
          setListMarker(cacheNearCoffee);
        }
        return isValid;
      }
      default: {
        return false;
      }
    }
  };

  const handleSearchTextByGoogle = async (location, type) => {
    const params = { radius: 1000 * 0.3048, location, query: type };
    try {
      let dataFilter: any = null;
      const res = await searchTextByGoogle(params);
      dataFilter = res.data;
      if (res?.data?.results?.length === 20 && res?.data?.next_page_token) {
        const res2 = await searchTextByGoogle({
          ...params,
          pagetoken: res.data?.next_page_token,
        });
        dataFilter = {
          ...(res2.data || {}),
          results: [...res.data?.results, ...(res2.data?.results || [])],
        };
      }
      saveCacheCategory(type, { ...dataFilter, date: new Date() });
      setListMarker(dataFilter);
    } catch (error) {}
  };

  const getListNear100Feet = async location => {
    const params = { radius: 1000 * 0.3048, location };
    try {
      let dataNearBy: any = null;
      const res = await getNearByGoogle(params);
      dataNearBy = res.data;
      if (res?.data?.results?.length === 20 && res?.data?.next_page_token) {
        const res2 = await getNearByGoogle({
          ...params,
          pagetoken: res.data?.next_page_token,
        });
        dataNearBy = {
          ...(res2.data || {}),
          results: [...res.data?.results, ...(res2.data?.results || [])],
        };
      }

      setNearLocationData(dataNearBy);
      setListMarker(dataNearBy);
      setTimeFetchMap(new Date());
    } catch (error) {}
  };

  const onGetTopCardPlace = marker => {
    let params: any = { placeName: marker.types[0], merchantName: marker.name };
    selectGooglePlace(params).then(res => {
      const data = res?.data?.data ? res?.data?.data[0] : null;
      setSelectedMarker({ ...marker, card: data });
    });
  };

  useEffect(() => {
    getCurrentLocation(position => {
      setTimeout(() => {
        setLocation(position);
      }, 500);

      const localNearData: any = getNearLocationData();
      const previousDay = getTimeFetchMap();
      const timePass = getMinuteFromTwoDate(new Date(), new Date(previousDay));
      const isValidExist = !!localNearData && !!previousDay && timePass <= 15;
      if (isValidExist) {
        return;
      }
      getListNear100Feet(
        `${position.coords.latitude},${position.coords.longitude}`,
      );
    });
    loggerHelper(logType.page_viewplacesnearyou_load);
    loggerHelper(logType.clicked_viewplacesnearyou_storeonmap);
  }, []);

  return (
    <div
      className="container-page my-profile-wrapper estimate__near-location-wrapper"
      aria-hidden="true"
    >
      <HomeNavbar history={props.history} />
      <div
        className={`col-md-12 p-0 m-auto reward-container near-location-container reward-grp-page full-height`}
      >
        <div className="menu-profile-wrapper help-wrapper near-location-content">
          <div className="text-center header-back-grp">
            <button
              type="button"
              className={`"btn btn-select float-left pl-0`}
              onClick={() => {
                history.goBack();
                loggerHelper(logType.page_viewplacesnearyou_exit);
              }}
            >
              <img
                src={previousIco}
                alt="img-previous"
                className="img-control-back"
              />
              <span className="back-text">Back</span>
            </button>
            <span className="title-selected mr-auto ml-auto">
              Stores near you
            </span>
          </div>
          <div className="container-menu-categories row ml-0 mr-0">
            <div className="col-12">
              <div className="title-container">
                Select a category and a place near you to see the best card.
              </div>
            </div>
            <div className="col-12 container-map">
              <div className="container-categories-map">
                {MAPCATEGORIES.map(category => (
                  <div
                    className={`box-category-wrapper ${
                      category.key === activeCategory
                        ? 'active-box-category'
                        : ''
                    }`}
                    key={category.key}
                    onClick={() => {
                      if (activeCategory === category.key) {
                        setListMarker(cacheLocation);
                        setActiveCategory(null);
                        return;
                      }
                      setActiveCategory(category.key);
                      const checkIsExistCache = checkExistCachegory(
                        category.key,
                      );
                      if (checkIsExistCache) {
                        return;
                      }
                      handleSearchTextByGoogle(
                        `${currentLocation.lat},${currentLocation.lng}`,
                        category.key,
                      );
                    }}
                  >
                    <div className="box-category-container">
                      <img
                        src={category.url}
                        alt="img"
                        className="category-img"
                      />

                      <span className="text-category">{category.label}</span>
                    </div>
                  </div>
                ))}
              </div>

              <GoogleMap
                listResult={listMarker?.results || []}
                center={currentLocation}
                onSelectMarker={marker => {
                  onGetTopCardPlace(marker);
                }}
              />
              {selectedMarker && (
                <div className="container-result-box">
                  {
                    selectedMarker?.card ? (
                      <div className="top-card content-card-top">
                        <div className="your-top-card">Your Top Card</div>
                        <div className="card-detail-container">
                          <div
                            className="close-icon-card"
                            onClick={() => {
                              setSelectedMarker(null);
                            }}
                          >
                            X
                          </div>
                          <div className="card-detail-header">
                            <img
                              src={
                                selectedMarker?.card?.cardImageUrl || creditCard
                              }
                              alt="card"
                              width="72px"
                              height="45px"
                            />
                            <div className="name-card">
                              {selectedMarker?.card?.cardNameShort || 'null'}
                              {' - '}
                              {selectedMarker?.card?.accountMask || 'null'}
                            </div>
                          </div>
                          <div className="info-card">
                            Use this card to earn{' '}
                            {!user.hasLinkedPlaid ? (
                              <span className="bold">X%</span>
                            ) : (
                              <span className="bold">
                                {!selectedMarker?.card?.potentialRewardText
                                  ? '1x rewards'
                                  : startsWith(
                                      selectedMarker?.card?.potentialRewardText,
                                      '0x',
                                    )
                                  ? selectedMarker?.card?.potentialRewardText?.replace(
                                      '0x',
                                      '1x',
                                    )
                                  : selectedMarker?.card?.potentialRewardText}
                              </span>
                            )}
                            &nbsp;at&nbsp;
                            <span className="bold underline">
                              {selectedMarker?.name || ''}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null
                    // <div className="top-card content-card-top  content-card-empty ">
                    //   <div className="your-top-card">Your Top Card</div>
                    //   <div className="card-detail-container">
                    //     <div
                    //       className="close-icon-card"
                    //       onClick={() => {
                    //         setSelectedMarker(null);
                    //       }}
                    //     >
                    //       X
                    //     </div>
                    //     <p className="text-center m-0 pt-3 pb-3">
                    //       No card for this place
                    //     </p>
                    //   </div>
                    // </div>
                  }
                  {selectedMarker?.card && (
                    <div className="box-button-next">
                      <button
                        className="btn btn-base btn-continue"
                        onClick={() => {
                          setOpenModalEstimate(!isOpenEstimateModal);
                          loggerHelper(
                            logType.clicked_viewplacesnearyou_button_next,
                          );
                        }}
                      >
                        Next
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
      <ModalEstimateRewardByPlace
        mappedMerchant={selectedMarker?.name || ''}
        isOpenModal={isOpenEstimateModal}
        toggle={() => {
          setOpenModalEstimate(!isOpenEstimateModal);
          setSelectedMarker(null);
          loggerHelper(logType.clicked_viewplacesnearyou_text_amount_exit);
        }}
        marker={selectedMarker}
      />
    </div>
  );
}
