import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { usePlaidLink } from 'react-plaid-link';
import { toast } from 'react-toastify';
import { upperFirst, filter } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { previousIco } from 'containers/Assets/images';
import { useListenerRewardsNew } from 'utils/socketIO';
import { ModalValidatePlaidFail } from '../../components/common';
import RouteNames from '../../app/RouteNames';
import { useMyAccountFormSlice } from './slice';
import { useHomeFormSlice } from '../HomePage/slice';
import { selectCardsMappingFailed } from '../HomePage/slice/selectors';
import {
  selectLinkedAccountData,
  selectPlaidData,
  selectPromptQuestion,
  selectLinkedAccountById,
  selectLoadingRelink,
  selectLoading,
  selectRelinkRedirect,
} from './slice/selectors';
import PromptModal from './PromptModal';
import Loader from '../Loader';
import CardValidationPage from '../CardValidationPage';
import { useProgressSlice } from 'containers/Layouts/slice';
import { alertLog, getTrackingUserClickPersonalizedAdvice } from 'api';
import { logType } from 'types/logTypes';
import { getUserRegister, setConnectPlaidReLink } from 'utils/localStorages';
import { loggerHelper } from 'utils/utils';
import {
  ModalConfirmUnlink,
  ModalConfirmUnlinkSuccess,
  ModalConfirmRelink,
  ModalConfirmRelinkSuccess,
} from './UnLinkReLink';

export default function MenuProfile(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isShowAddCard, setIsNewAddCard] = useState(true);
  const [isOpenFail, setOpenFail] = useState(false);
  const [failedData, setFailedData] = useState<any>({});
  const [isOpenModalPrompt, setIsOpenModalPrompt] = useState(false);
  const [selectedUpLink, setSelectedUplink] = useState(null);
  // unlink/relink
  const [isOpenUnlink, setIsOpenUnlink] = useState(false);
  const [isOpenUnlinkSuccess, setIsOpenUnlinkSuccess] = useState(false);
  const [isOpenRelink, setIsOpenRelink] = useState(false);
  const [isOpenRelinkSuccess, setIsOpenRelinkSuccess] = useState(false);
  const [cardUnRelink, setCardUnRelink] = useState<any>(null);

  const { actions } = useMyAccountFormSlice();
  const { actions: progressActions } = useProgressSlice();
  const { actions: homeActions } = useHomeFormSlice();
  const linkedAccount = useSelector(selectLinkedAccountData);
  const plaidConfigData = useSelector(selectPlaidData);
  const linkedAccountById = useSelector(selectLinkedAccountById);
  const loadingRelink = useSelector(selectLoadingRelink);
  const loadingDefaul = useSelector(selectLoading);
  const isRelinkRedirect = useSelector(selectRelinkRedirect);

  const [, doAddConnect] = useListenerRewardsNew(data => {
    if (data?.data?.loading === 100) {
      handleGetLinkedAccount();
      dispatch(
        actions.getRewardPrompt({ cb: () => setIsOpenModalPrompt(true) }),
      );
      dispatch(
        actions.getPlaidLinkByUser({
          cb: ({ data, success }) => {
            if (success) {
              if (data?.mappedCardsFailed?.length) {
              }
            }
          },
        }),
      );
    }
  });
  const rewardPromptQuestion = useSelector(selectPromptQuestion);
  const handleGetLinkedAccount = () => {
    dispatch(actions.getLinkedAccount());
  };

  const onRelink = () => {
    dispatch(actions.getReLinkPlaid({ id: failedData?.idError }));
    setOpenFail(!isOpenFail);
  };

  const onSuccess = useCallback(
    (token, metadata) => {
      localStorage.setItem('reLinkPlaidFrom', '');
      if (!!selectedUpLink || !!failedData?.idError) {
        dispatch(
          actions.updateReLinkPlaid({
            metadata,
            id: selectedUpLink || failedData?.idError,
            cb: () => {
              handleGetLinkedAccount();
              setFailedData({});
            },
          }),
        );
        setConnectPlaidReLink(null);
        return;
      } else {
        getTrackingUserClickPersonalizedAdvice({
          eventType: 'NEW_ACCOUNT_LINKING_SUCCESS',
          eventData: metadata,
        }).catch(error => console.log(error));
      }
      if (!isCardValidaton) dispatch(progressActions.setLinkingOn());
      dispatch(
        actions.validatePlaid({
          token: token,
          metadata,
          cb: ({ success, data }) => {
            if (success && doAddConnect) {
              doAddConnect();
              return;
            }
            if (
              success &&
              data.message !== 'ACCOUNTS_LINKED' &&
              data.message !== 'GLOBAL_ACCOUNT_LINKED'
            ) {
              dispatch(
                homeActions.getCardsMappingFailed({
                  cb: success => {
                    if (success) setIsCardValidaton(true);
                  },
                }),
              );
            }
            if (!success) {
              setOpenFail(!isOpenFail);
              setFailedData(data);
            }
          },
        }),
      );
    },
    [selectedUpLink, failedData?.idError],
  );

  const checkPlaidErrorCode = useCallback(async (errorType, errorReason) => {
    const userDetails = await getUserRegister();
    switch (errorType) {
      case 'INVALID_INPUT':
      case 'INVALID_REQUEST':
      case 'INVALID_RESULT': {
        alertLog({
          alarmName: 'UNSUCCESSFUL_PLAID_SDK_INIT',
          userId: userDetails?.userId,
          errorReason,
        });
        break;
      }

      default: {
        if (!!selectedUpLink || !!failedData?.idError) {
          alertLog({
            alarmName: selectedUpLink
              ? 'RENEW_CONNECTION_FAILED'
              : 'PLAID_UPDATE_MODE_FAILED',
            userId: userDetails?.userId,
            errorReason,
            payload: {
              itemId: selectedUpLink || failedData?.idError || null,
            },
          });
        }

        break;
      }
    }
  }, []);

  const onLinkFailed = useCallback(
    (error, metadata) => {
      if (!!error) {
        localStorage.setItem('reLinkPlaidFrom', '');
        if (!!selectedUpLink) {
          setSelectedUplink(null);
          setConnectPlaidReLink(null);
        } else {
          getTrackingUserClickPersonalizedAdvice({
            eventType: 'NEW_ACCOUNT_LINKING_FAIL',
            eventData: {
              messageError:
                error?.display_message || error?.error_message || '',
            },
          }).catch(error => console.log(error));
        }
        const errorReason = error?.display_message || error?.error_message;
        checkPlaidErrorCode(error?.error_type, errorReason);
        if (!!error && !!error?.display_message) {
          toast.error(
            JSON.stringify(
              error?.display_message || error?.error_message || '',
            ),
          );
          dispatch(progressActions.setLinkingOff());
          setSelectedUplink(null);
        }
      }
    },
    [selectedUpLink],
  );

  useEffect(() => {
    loggerHelper(logType.page_linkedaccounts_load);
    if (!isRelinkRedirect) handleGetLinkedAccount();
  }, []);

  useEffect(() => {
    const reLinkFrom = localStorage.getItem('reLinkPlaidFrom') || null;
    if (
      loadingRelink === false &&
      reLinkFrom === 'linkedAccount' &&
      isRelinkRedirect
    ) {
      handleGetLinkedAccount();
      localStorage.setItem('reLinkPlaidFrom', '');
      dispatch(
        actions.setLoadingRelink({
          isLoad: false,
          isRedirect: false,
        }),
      );
    }
  }, [loadingRelink]);

  const location = useLocation();
  useEffect(() => {
    return history.listen(loca => {
      if (
        location.pathname === '/my-account/linked-account' &&
        loca.pathname !== location.pathname
      ) {
        loggerHelper(logType.page_linkedaccounts_exit);
      }
    });
  }, [history]);

  const onOpenPlaid = () => {
    dispatch(actions.getLinkPlaid());
  };
  const onUpdatedPrompt = () => {
    setIsOpenModalPrompt(false);
  };

  // ------------------------------- card vaidation
  const titles = {
    1: 'Card Validation',
    2: 'Card Selection',
    3: 'Card Validation',
    4: 'Card Selection',
    5: 'Card Validation',
    6: 'Card Validation',
    7: 'Repeat Card',
    8: '',
  };
  const [isCardValidaton, setIsCardValidaton] = useState(false);
  const [cardConfig, setCardConfig] = useState({
    step: 1,
    title: 'Card Validation',
  });
  const [cardValidate, setCardValidate] = useState<any>(null);
  const cardsMappingFailed = useSelector(selectCardsMappingFailed);
  //count card pending
  const countCardsPending = useMemo(
    () =>
      filter(cardsMappingFailed, function (o) {
        return o.linkStatus === 'PENDING';
      }).length || 0,
    [cardsMappingFailed],
  );

  const handleBackStepCardValidation = () => {
    switch (cardConfig?.step) {
      case 1: {
        setIsCardValidaton(false);
        setCardConfig({
          step: 1,
          title: titles[1],
        });
        break;
      }
      case 3:
      case 4:
      case 5: {
        setCardConfig({
          step: 1,
          title: titles[1],
        });
        break;
      }
      default:
        setCardConfig({
          step: cardConfig?.step - 1,
          title: titles[cardConfig?.step - 1],
        });
    }
  };

  const handleGoToStepCardValidation = step => {
    if (step === 'home') {
      setIsCardValidaton(false);
      setCardConfig({
        step: 1,
        title: 'Card Validation',
      });
      history.push(RouteNames.homePage.path);
    }
    setCardConfig({
      step,
      title: titles[step],
    });
  };

  const handleGoToCardValidation = (cardFailed, cardStatus) => {
    setCardValidate(cardFailed);
    switch (cardStatus) {
      case 'NOT_VALIDATED':
        setCardConfig({
          step: 2,
          title: titles[2],
        });
        setIsCardValidaton(true);
        loggerHelper(logType.clicked_myaccount_list_linkedaccounts_validate);
        break;
      case 'PENDING':
        dispatch(homeActions.getCardsMappingFailed({}));
        setCardConfig({
          step: 3,
          title: titles[3],
        });
        setIsCardValidaton(true);
        break;
      default:
        break;
    }
  };

  // unlink/relink
  const status = {
    Y: 'Unlink',
    N: 'ReLink',
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const handleGetLinkedAccountById = (id: string) => {
  //   dispatch(
  //     actions.getLinkedAccountById({
  //       id,
  //       cb: () => {
  //         setIsOpenUnlink(true);
  //       },
  //     }),
  //   );
  // };
  const doPlaidUnlink = () => {
    setIsLoading(true);
    const params = { accountId: cardUnRelink.accountId };
    if (params) {
      dispatch(
        actions.confirmUnlink({
          ...params,
          cb: success => {
            if (success) {
              setIsOpenUnlink(false);
              setIsOpenUnlinkSuccess(true);
            } else toast.error('Confirm Unlink Error');
            setIsLoading(false);
            dispatch(actions.unlinkSuccess());
          },
        }),
      );
    }
  };
  const doPlaidRelink = params => {
    if (params) {
      dispatch(
        actions.confirmRelink({
          ...params,
          cb: success => {
            if (success) {
              setIsOpenRelinkSuccess(true);
            } else toast.error('Confirm Relink Error');
          },
        }),
      );
    }
  };
  const onSuccessUnReLink = (token, metadata) => {
    try {
      const _params: any = {};
      _params.metadata = metadata;
      _params.token = token;

      if (cardUnRelink.linkedStatus === 'N') {
        if (!!cardUnRelink?.itemId) {
          _params.itemId = cardUnRelink.itemId;
        }
        doPlaidRelink(_params);
      }
    } catch (error) {}
  };

  const onGetTokenAccountOpenPlaid = () => {
    if (cardUnRelink) {
      dispatch(
        actions.getPlaidTokenByAccount({
          id: cardUnRelink?.accountId,
          params: { platform: 'web' },
          cb: () => {
            setIsOpenRelink(false);
          },
        }),
      );
    }
    return;
  };

  const config = {
    token: plaidConfigData?.link_token || '',
    onSuccess: !cardUnRelink ? onSuccess : onSuccessUnReLink,
    onExit: !cardUnRelink ? onLinkFailed : () => {},
    // onEvent,
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (!ready || !plaidConfigData?.link_token) {
      return;
    }
    localStorage.setItem('link_token', plaidConfigData?.link_token);
    open();
  }, [ready, open, plaidConfigData?.link_token]);

  return (
    <div className="profile-link-account-wrapper">
      {isCardValidaton ? (
        <>
          <div className="text-center header-back-grp">
            <button
              type="button"
              className="btn btn-select float-left"
              onClick={handleBackStepCardValidation}
            >
              <img
                src={previousIco}
                alt="img-previous"
                className="img-control-back"
              />
              <span className="back-text">Back</span>
            </button>
            <span className="title-selected">
              {countCardsPending === cardsMappingFailed?.length &&
              cardConfig?.step === 1
                ? 'Pending '
                : ''}
              {cardConfig?.title}
            </span>
          </div>
          <CardValidationPage
            isLinked
            isEarned={false}
            notValidated={linkedAccount.notValidated}
            step={cardConfig?.step}
            onOpenPlaid={onOpenPlaid}
            cardValidateLinked={cardValidate}
            handleGoToCardValidation={() => {}}
            handleGetRewardSummary={() => {}}
            handleGetRewardEarned={() => {}}
            handleGetLinkedAccount={handleGetLinkedAccount}
            handleGoToStep={handleGoToStepCardValidation}
          />
        </>
      ) : (
        <>
          <div className="text-center header-back-grp">
            <button
              type="button"
              className={`"btn btn-select float-left pl-0`}
              onClick={() => history.goBack()}
            >
              <img
                src={previousIco}
                alt="img-previous"
                className="img-control-back"
              />
              <span className="back-text cursor">Back</span>
            </button>
            <span className="title-selected mr-auto ml-auto">
              Linked Accounts
            </span>

            {isShowAddCard && (
              <div className="col-md-10 p-0 m-auto">
                <div className="col-md-11 p-0 m-auto">
                  <div className="link-more-account-suggest border-radius mr-0">
                    <div className="txt-link-more">
                      <p className="txt-question-add mb-0">
                        Do you want to add another card for you or a member of
                        your household?
                      </p>
                      <button
                        onClick={() => {
                          onOpenPlaid();
                          getTrackingUserClickPersonalizedAdvice({
                            eventType: 'NEW_ACCOUNT_CLICK',
                          }).catch(error => console.log(error));
                        }}
                        className="link-more-account-question-btn ml-2 link-more-yes"
                      >
                        Yes, take me there.
                      </button>
                      <button
                        className="link-more-account-question-btn ml-2"
                        onClick={() => setIsNewAddCard(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="link-account-content">
            <div className="row content-link-account ml-auto mr-auto">
              <div className="col-md-6 col-sm-8 col-lg-5 col-xl-4 ml-auto mr-auto">
                {linkedAccount?.update2Links?.length ? (
                  <div className="box-card" style={{ marginBottom: 40 }}>
                    <div className="box-link-content">
                      <p className="text-label-box">{`Update ${linkedAccount.update2Links.length} links`}</p>
                      <p className="text-des-box">
                        Sign into these accounts to keep your rewards and
                        personalized advice up to date.
                      </p>
                      {linkedAccount.update2Links.map((card, key) => (
                        <div className="card-item-link" key={key}>
                          <div className="left-card d-flex align-items-center">
                            <div className="box-img">
                              <img
                                className="card-img"
                                src={card?.cardImage || null}
                                alt="card-img"
                              />
                            </div>
                            <span
                              className="card-name"
                              dangerouslySetInnerHTML={{
                                __html: `${card.institutionName}${
                                  !!card?.accountSubtype
                                    ? `<br/>${upperFirst(
                                        card.accountSubtype,
                                      )}  - ${card.accountMask}`
                                    : ` - ${card.accountMask}`
                                }`,
                              }}
                            />
                          </div>
                          <div
                            className="right-card cursor"
                            onClick={() => {
                              setSelectedUplink(card?.accountId);
                              loggerHelper(
                                logType.clicked_myaccount_list_linkedaccounts_update,
                              );
                              dispatch(
                                actions.getReLinkPlaid({
                                  id: card?.accountId,
                                  isRenew: true,
                                }),
                              );
                              setConnectPlaidReLink({
                                accountId: card?.accountId,
                              });
                              localStorage.setItem(
                                'reLinkPlaidFrom',
                                'linkedAccount',
                              );
                            }}
                          >
                            Update
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                {linkedAccount?.notValidated?.length ? (
                  <div className="box-card" style={{ marginBottom: 40 }}>
                    <CardValidationPage
                      isLinked
                      isEarned={false}
                      notValidated={linkedAccount.notValidated}
                      step="linked-accounts"
                      onOpenPlaid={() => {}}
                      handleGoToStep={() => {}}
                      cardValidateLinked={null}
                      handleGetRewardSummary={() => {}}
                      handleGetLinkedAccount={() => {}}
                      handleGetRewardEarned={() => {}}
                      handleGoToCardValidation={handleGoToCardValidation}
                    />
                  </div>
                ) : null}
                {linkedAccount?.upToDate?.length ? (
                  <div className="box-card">
                    <div className="box-link-content">
                      <p className="text-label-box">Up to date</p>
                      <p className="text-des-box">
                        These accounts are good to go - no action needed.
                      </p>
                      <div className="container-list-card">
                        {linkedAccount.upToDate.map((card, key) => (
                          <div
                            className={`card-item-link${
                              card.linkedStatus !== 'Y' ? ' relink' : ''
                            }`}
                            key={key}
                          >
                            <div className="left-card d-flex align-items-center">
                              <div className="box-img">
                                <img
                                  className="card-img"
                                  src={card?.cardImage || null}
                                  alt="card-img"
                                />
                              </div>
                              <span
                                className="card-name"
                                dangerouslySetInnerHTML={{
                                  __html: `${card.institutionName}${
                                    !!card?.accountSubtype
                                      ? `<br/>${upperFirst(
                                          card.accountSubtype,
                                        )}  - ${card.accountMask}`
                                      : ` - ${card.accountMask}`
                                  }`,
                                }}
                              />
                            </div>
                            <div className="right-card">
                              <div
                                className="card-btn"
                                onClick={() => {
                                  setCardUnRelink(card);
                                  if (card.linkedStatus === 'Y') {
                                    // handleGetLinkedAccountById(card.accountId);
                                    setIsOpenUnlink(true);
                                    loggerHelper(
                                      logType.clicked_myaccount_list_linkedaccounts_unlink,
                                    );
                                  } else {
                                    setIsOpenRelink(true);
                                    loggerHelper(
                                      logType.clicked_myaccount_list_linkedaccounts_relink,
                                    );
                                  }
                                }}
                              >
                                {status[card.linkedStatus]}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
      <ModalValidatePlaidFail
        isOpen={isOpenFail}
        toggle={() => setOpenFail(!isOpenFail)}
        messageType={failedData?.message}
        onClickLinkOtherAccount={() => {
          if (failedData?.message === 'ACCOUNTS_LINKED') {
            onRelink();
            return;
          }
          setOpenFail(!isOpenFail);
          onOpenPlaid();
        }}
      />
      <PromptModal
        isOpenModalPrompt={isOpenModalPrompt && !!rewardPromptQuestion?.length}
        data={rewardPromptQuestion}
        onUpdatedPrompt={onUpdatedPrompt}
      />
      <ModalConfirmUnlink
        isOpen={isOpenUnlink}
        card={cardUnRelink}
        listCards={linkedAccountById}
        toggle={() => setIsOpenUnlink(false)}
        onSubmit={doPlaidUnlink}
      />
      <ModalConfirmUnlinkSuccess
        isOpen={isOpenUnlinkSuccess}
        card={cardUnRelink}
        listCards={linkedAccountById}
        toggle={() => {
          setIsOpenUnlinkSuccess(false);
          setCardUnRelink(null);
          handleGetLinkedAccount();
        }}
      />
      <ModalConfirmRelink
        isOpen={isOpenRelink}
        card={cardUnRelink}
        toggle={() => setIsOpenRelink(false)}
        onSubmit={onGetTokenAccountOpenPlaid}
      />
      <ModalConfirmRelinkSuccess
        isOpen={isOpenRelinkSuccess}
        card={cardUnRelink}
        toggle={() => {
          setIsOpenRelinkSuccess(false);
          setCardUnRelink(null);
          handleGetLinkedAccount();
        }}
      />
      <Loader isConnecting={isLoading || loadingRelink || loadingDefaul} />
    </div>
  );
}
