import React, { useEffect, useState, useCallback } from 'react';
import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnExit,
  PlaidLinkOptions,
} from 'react-plaid-link';
import { useDispatch } from 'react-redux';
import { useHomeFormSlice } from '../HomePage/slice';
import { useMyAccountFormSlice } from '../MyProfile/slice';
import { useHistory } from 'react-router-dom';
import {
  getConnectPlaidReLink,
  setConnectPlaidReLink,
} from 'utils/localStorages';
import { getTrackingUserClickPersonalizedAdvice } from 'api';

const OAuthLink = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useHomeFormSlice();
  const { actions: actionsMyAccount } = useMyAccountFormSlice();

  const [token, setToken] = useState<string | null>(null);
  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

  React.useEffect(() => {
    if (isOAuthRedirect) {
      setToken(localStorage.getItem('link_token'));
      return;
    }
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    const reLinkFrom = localStorage.getItem('reLinkPlaidFrom') || null;
    const plaidRelink = getConnectPlaidReLink();
    if (plaidRelink?.accountId) {
      dispatch(
        actionsMyAccount.updateReLinkPlaid({
          metadata,
          id: plaidRelink.accountId,
          cb: () => {
            if (reLinkFrom === 'linkedAccount')
              dispatch(
                actionsMyAccount.setLoadingRelink({
                  isLoad: false,
                  isRedirect: true,
                }),
              );
            if (reLinkFrom === 'Earned') {
              dispatch(
                actions.getRewardEarned({
                  cb: () => {
                    dispatch(actions.loadingRelinkEarned({ isLoad: false }));
                    localStorage.setItem('reLinkPlaidFrom', '');
                  },
                }),
              );
            }
            setConnectPlaidReLink({});
          },
        }),
      );
    } else {
      getTrackingUserClickPersonalizedAdvice({
        eventType: 'NEW_ACCOUNT_LINKING_SUCCESS',
        eventData: metadata,
      }).catch(error => console.log(error));
      dispatch(actions.validatePlaid({ token: publicToken, metadata }));
    }
    if (reLinkFrom === 'linkedAccount') {
      dispatch(
        actionsMyAccount.setLoadingRelink({
          isLoad: true,
          isRedirect: true,
        }),
      );
      history.push('/my-account/linked-account');
    } else if (reLinkFrom === 'Earned') {
      dispatch(actions.loadingRelinkEarned({ isLoad: true }));
      history.push('/', { relinkEarned: '1' });
    } else history.push('/');
  }, []);

  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    if (error) {
      alert(error || 'Validate Plaid OAuth Failed');
    }
    const plaidRelink = getConnectPlaidReLink();
    if (plaidRelink?.accountId) {
      getTrackingUserClickPersonalizedAdvice({
        eventType: 'NEW_ACCOUNT_LINKING_FAIL',
        eventData: {
          messageError: error?.display_message || error?.error_message || '',
        },
      }).catch(error => console.log(error));
    }
    setConnectPlaidReLink({});
    history.push('/');
  }, []);

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onExit,
  };

  if (isOAuthRedirect) {
    config.receivedRedirectUri = window.location.href;
  }

  const { open, ready } = usePlaidLink(config);
  useEffect(() => {
    if (isOAuthRedirect && ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);
  return null;
};
export default OAuthLink;
