import iconRestaurant from 'containers/Assets/images/restaurant.svg';
import iconGas from 'containers/Assets/images/gas.svg';
import iconGroceries from 'containers/Assets/images/groceries.svg';
import iconCoffee from 'containers/Assets/images/coffee.svg';

export const ONBOARDINGS = [
  {
    id: '1',
    label: 'View rewards you are missing',
    uri:
      'https://f16.photo.talk.zdn.vn/8849790215965534516/407eb8b19840511e0851.jpg',
  },
  {
    id: '2',
    label: 'And how to get them',
    uri:
      'https://f14.photo.talk.zdn.vn/3392051073402673820/5223a1ef811e4840110f.jpg',
  },
  {
    id: '3',
    label: 'See the best card to use',
    uri:
      'https://f11.photo.talk.zdn.vn/8778774964957569102/61b99075b08479da2095.jpg',
  },
  {
    id: '4',
    label: 'Even at places near you',
    uri:
      'https://f4.photo.talk.zdn.vn/2915984982144035007/9fa7646b449a8dc4d48b.jpg',
  },
];

export const MAPCATEGORIES = [
  {
    key: 'restaurants',
    label: 'Restaurants',
    url: iconRestaurant,
  },
  {
    key: 'gas',
    label: 'Gas',
    url: iconGas,
  },
  {
    key: 'groceries',
    label: 'Groceries',
    url: iconGroceries,
  },
  {
    key: 'coffee',
    label: 'Coffee',
    url: iconCoffee,
  },
  // {
  //     key: 'hotel',
  //     label: 'Hotel',
  //     url: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/lodging-71.png'
  // },
  // {
  //     key: 'hospital',
  //     label: 'Hospital',
  //     url: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/hospital-71.png'
  // },
  // {
  //     key: 'atm',
  //     label: 'Atm',
  //     url: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/atm-71.png'
  // },
];

export const linkOurStory = 'https://www.uthrive.club/about-us';

export const linkPlayStore =
  'https://uthrive.app.link/download-uthrive-android';

export const linkAppleStore = 'https://uthrive.app.link/download-uthrive-ios';

export const linkMedia = 'https://www.uthrive.club/media';

export const linkDownLoadExtension = [
  {
    key: 'chrome',
    label: 'Add to Chrome',
    value:
      'https://chrome.google.com/webstore/detail/uthrive-use-best-cards-fo/kmajiclhfhbncoegkfboeldogkdbmkhj?hl=en-US',
  },
  {
    key: 'edge',
    label: 'Add to Microsoft Edge',
    value:
      'https://microsoftedge.microsoft.com/addons/detail/uthrive-use-best-cards-f/dnhdlmmgfndagkilkidkkjmeepkpnpdd',
  },
  {
    key: 'firefox',
    label: 'Add to Firefox',
    value:
      'https://addons.mozilla.org/en-US/firefox/addon/uthrive-maximize-rewards/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search',
  },
  {
    key: 'safari',
    label: 'Add to Safari',
    value:
      'https://apps.apple.com/vn/app/uthrive-maximize-rewards/id6443603960?mt=12',
  },
];

export const listIssue = [
  {
    label: 'Privacy concerns',
    value: 'privacy-concerns',
  },
  {
    label: 'I use other cash back or coupon sites',
    value: 'cash-back-coupon-sites',
  },
  {
    label: 'No savings',
    value: 'no-savings',
  },
  {
    label: 'The Uthrive window shows too often',
    value: 'honey-window-too-often',
  },
  {
    label: 'It slows down my computer',
    value: 'slows-computer-input',
  },
  {
    label: 'Work computer',
    value: 'work-computer',
  },
  {
    label: 'Other, please explain:',
    value: 'other',
  },
];
