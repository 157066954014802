import React from 'react';
import { ModalBase } from './ModalBase';
import { iconUnlinkSuccess } from 'containers/Assets/images';

export const ModalConfirmUnlinkSuccess = props => {
  const { toggle, isOpen, card, listCards } = props;

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      className="modal-confirm-card-link"
    >
      <div className="modal-confirm-card-link__content d-flex flex-column justify-content-between">
        <div>
          <div className="modal-confirm-card-link__title">
            Unlinking your account successful.
          </div>
          <div className="modal-confirm-card-link__message">
            The following account has been successfully unlinked.
          </div>
          {false ? (
            <div className="modal-confirm-card-link__list">
              {listCards?.map(item => (
                <div key={item.id} className="modal-confirm-card-link__card">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="card-inner d-flex justify-content-between align-items-center">
                      <div className="card-img">
                        {card?.cardImage ? (
                          <img
                            src={card?.cardImage || null}
                            alt="card-avatar"
                          />
                        ) : (
                          <div className="card-img border" />
                        )}
                      </div>
                      <span className="card-name">
                        {item?.institutionName}
                        {' - '}
                        {item?.accountMask}
                      </span>
                    </div>
                    <img
                      src={iconUnlinkSuccess}
                      alt="icon-checked"
                      className="card-icon"
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="modal-confirm-card-link__card">
              <div className="d-flex justify-content-between align-items-center">
                <div className="card-inner d-flex justify-content-between align-items-center">
                  <div className="card-img border">
                    {card?.cardImage ? (
                      <img src={card?.cardImage} alt="card-avatar" />
                    ) : (
                      <div className="card-img border" />
                    )}
                  </div>
                  <span className="card-name">
                    {card?.institutionName}
                    {' - '}
                    {card?.accountMask}
                  </span>
                </div>
                <img
                  src={iconUnlinkSuccess}
                  alt="icon-checked"
                  className="card-icon"
                />
              </div>
            </div>
          )}
        </div>
        <div className="modal-confirm-card-link__btn" onClick={toggle}>
          Continue
        </div>
      </div>
    </ModalBase>
  );
};
