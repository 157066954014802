import { prettifyDoaminPath, pathNames } from '../path';
import axios from 'axios';

export const register = payload =>
  axios.post(`${prettifyDoaminPath(pathNames.register)}`, {
    ...payload,
    deviceType: 'web',
  });

export const verifyUser = payload =>
  axios.post(`${prettifyDoaminPath(pathNames.verifyUser)}`, payload);

export const getUserByEmail = params =>
  axios.get(`${prettifyDoaminPath(pathNames.getUserByEmail)}`, { params });

export const verifyCognitoUser = () => {
  return axios.get(`${prettifyDoaminPath(pathNames.verifyCognitoUser)}`);
};

export const trackLoginSuccess = payload => {
  return axios.post(`${prettifyDoaminPath(pathNames.trackLoginSuccess)}`, {
    ...payload,
    deviceType: 'web',
  });
};

export const trackLoginFail = payload => {
  return axios.post(`${prettifyDoaminPath(pathNames.trackLoginFail)}`, {
    ...payload,
  });
};

export const updateUserRegister = ({ ...payload }) => {
  return axios.put(`${prettifyDoaminPath(pathNames.register)}`, payload);
};

export const getUserById = id =>
  axios.get(`${prettifyDoaminPath(pathNames.myUserRegister)}/${id}`);

export const updateCognitoPhone = ({ ...payload }) => {
  return axios.post(
    `${prettifyDoaminPath(pathNames.updateCognitoPhone)}`,
    payload,
  );
};

export const mappedCardFailed = payload =>
  axios.put(`${prettifyDoaminPath(pathNames.mappedCardFailed)}`, payload);
