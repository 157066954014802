import * as React from 'react';
import { getTrackingUserClickPersonalizedAdvice } from 'api';

export const LinkMoreAccountsButton = ({ onOpenPlaid }) => {
  return (
    <div className="col-md-12 text-center pb-3">
      <button
        onClick={() => {
          onOpenPlaid();
          getTrackingUserClickPersonalizedAdvice({
            eventType: 'NEW_ACCOUNT_CLICK',
          }).catch(error => console.log(error));
        }}
        // disabled={disabled}
        type="button"
        className="btn btn-base btn-link-more-account"
      >
        Link More Accounts
      </button>
    </div>
  );
};
