import { Footer } from 'components';
import { LogoFont } from 'components/common';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import './styles.scss';
import Ulogo from 'containers/Assets/images/Uthrive-logo-short128.png';
import wallet from 'containers/Assets/images/wallet.gif';
import { getUserRegister } from 'utils/localStorages';
import { sendLinkDownloadApp } from 'api';

export default function HomeAccessMobile(props: any) {
  const [phone, setPhone] = useState('');
  const [user, setUser] = useState<any>({});
  const [err, setErr] = useState(false);
  useEffect(() => {
    const data = getUserRegister();
    setUser(data);
    if (data?.phone_number) {
      setPhone(data?.phone_number);
    }
  }, []);

  const handleChangePhone = e => {
    setErr(false);
    setPhone(e.target.value);
  };

  const handleClickSendLink = () => {
    if (phone) {
      sendLinkDownloadApp({ phoneNumber: phone }).catch(err => {
        console.log(err);
        setErr(true);
      });
    } else {
      setErr(true);
    }
  };

  const handleClickEnableExtension = () => {
    window.open('https://uthrive.app.link/enable-extension', '_blank');
  };

  const handleClickHowItWork = () => {
    window.open('https://uthrive.app.link/how-see-it-work-extension', '_blank');
  };

  const handleClickSignOut = () => {
    window.location.replace('https://www.uthrive.club/');
    localStorage.clear();
  };

  return (
    <div className="HomeAccessMobile-wrapper">
      <div className="HomeAccessMobile-header navbar">
        <LogoFont />
        {user && (
          <button
            className="btn btn-login btn-text text-decoration-none"
            onClick={handleClickSignOut}
          >
            Sign out
          </button>
        )}
      </div>
      <div className="HomeAccessMobile-body">
        <div className="HomeAccessMobile-qrcode-container">
          <div className="notify-text">
            <span>Hi {user ? user?.username : 'there'},</span>
            <br />
            Now Uthrive can only be accessed via our
            <br />
            mobile app – it’s simple, easy to use and safe.
          </div>
          <div className="qr-wrapper">
            <div className="qr-text">Scan to download the app</div>
            <QRCode
              size={60}
              value={
                process.env.REACT_APP_LINK_GET_APP ||
                'https://uthrive.app.link/'
              }
            />
          </div>
        </div>
        <div className="HomeAccessMobile-contents">
          <div className="HomeAccessMobile-card">
            <div className="img-wrapper">
              <img src={Ulogo} alt="uthrive" />
            </div>
            <div className="card-text">
              Send a link to your phone number to download Uthrive app.
            </div>
            <div className="input-wrapper">
              <input value={phone} onChange={handleChangePhone} name="phone" />
              <div className="line"></div>
              <label>Your phone number (recommended)</label>
              {err && <div className="err">Phone invalid</div>}
            </div>
            <button
              type="button"
              className="btn btn-base btn-signup btn-card"
              onClick={handleClickSendLink}
            >
              Send a link
            </button>
          </div>
          <div className="HomeAccessMobile-card">
            <div className="img-wrapper-no-box-shadow">
              <img src={wallet} alt="uthrive" />
            </div>
            <div className="card-text">
              Enable Uthrive extension to know the best card to use before
              shopping online.
            </div>
            <button
              type="button"
              className="btn btn-base btn-signup btn-card"
              onClick={handleClickEnableExtension}
            >
              Enable extension
            </button>
            <div className="how-it-work" onClick={handleClickHowItWork}>
              <div className="play-btn">
                <div className="triangle"></div>
              </div>
              <div className="how-it-work-text">See how it works</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
