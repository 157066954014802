import React, { useState, useEffect } from 'react';

export function NameCardInput({
  onChange,
  validate,
  setValidate,
  value,
  reset,
}) {
  const [inputText, setInputText] = useState('');
  useEffect(() => {
    if (!inputText && value) {
      setInputText(value);
    }
    if (reset) {
      setInputText('');
    }
  }, [value, reset, validate]);

  const handleChange = e => {
    const value = e?.target?.value;
    setInputText(value);
    onChange(value);
    if (value.length >= 4) setValidate(false);
  };

  const validateReal = validate && inputText.length < 4;
  return (
    <div
      className={`card-name-input ${
        validateReal ? 'card-name-input--error' : ''
      }`}
    >
      <input
        type="text"
        name="cardName"
        value={inputText}
        autoFocus={validate}
        autoComplete="off"
        placeholder="Enter card name, e.g., Citi Double Cash"
        onChange={handleChange}
        onClick={() => setValidate(true)}
      />
      {inputText && inputText.length < 4 && (
        <span>Enter at least 4 characters for card name</span>
      )}
    </div>
  );
}
