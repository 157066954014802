import React from 'react';
import './styles.scss';
import logo from 'containers/Assets/images/logo.svg';

const RatingAppThank = () => {
  return (
    <div className="ratings-page-thank-wrapper">
      <div className="ratings-page-thank-wrapper--body">
        <div className="ratings-page-thank-wrapper--header">
          <div className="ratings-page-thank-wrapper--header-logo-wrapper">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="ratings-page-thank-wrapper--body-title">
          <h1>Thanks for sharing your thoughts.</h1>
          <h5>Your feedback helps enhance Uthrive experience for everyone.</h5>
        </div>
        <div className="rating-thank-footer">
          © Copyright 2021 Uthrive Inc. All rights reserved.{' '}
          <a
            href="https://www.uthrive.club/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};
export default RatingAppThank;
