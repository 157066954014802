import React, { useState, useEffect } from 'react';
// import { loggerHelper } from 'utils/utils';
// import { logType } from 'types/logTypes';
import { NameCardInput } from './Custominput';

export function SelectYourCard({ cardsLinked, cardValidate, hanldeConfirm }) {
  const [cardEdit, setCardEdit] = useState<any>(null);
  const [cardSelected, setCardSelected] = useState<any>(null);
  const [cardName, setCardName] = useState(
    cardValidate?.linkStatus === 'PENDING'
      ? cardValidate?.otherCardUserPrompt
      : '',
  );
  const [validate, setValidate] = useState(false);
  useEffect(() => {
    if (cardValidate?.linkStatus === 'PENDING') {
      setCardName(cardValidate?.otherCardUserPrompt || '');
      setCardEdit('EDIT_CARD');
      setValidate(true);
      const selectCard = document.getElementById('select-card');
      if (selectCard) selectCard.scrollTop = selectCard.scrollHeight;
    }
  }, [cardValidate]);

  const onChange = cardName => {
    setCardName(cardName);
  };

  const onConfirm = () => {
    if (cardSelected) {
      hanldeConfirm({
        mappedCardId: cardSelected?.mappedCardId,
        isConfirm: 'N',
        cb: () => {
          setCardSelected(null);
          setCardName('');
        },
      });
    } else if (cardName) {
      hanldeConfirm({
        otherCard: cardName,
        isConfirm: 'N',
        cb: () => {
          setCardSelected(null);
          setCardName('');
        },
      });
    }
  };
  return (
    <div className="card-validation">
      <div className="card-validation__title">{`${
        cardsLinked?.institutionShortName || ''
      } ${cardsLinked?.accountSubtype || ''} - ${
        cardsLinked?.accountMask || ''
      }`}</div>
      <div className="card-validation__message">
        Please validate the name of above card from options below to link it
        successfully:
      </div>
      <ul id="select-card" className="card-validation__select">
        {(cardsLinked?.cards || []).map(item => (
          <li
            key={item.mappedCardId}
            onClick={() => {
              setValidate(false);
              setCardEdit('');
              setCardSelected(item);
            }}
          >
            <input
              type="radio"
              id={item.mappedCardId}
              name="spend"
              value={item.mappedCardId}
              onChange={e => {}}
            />
            <label htmlFor={item.mappedCardId}>
              <div className="card-image border">
                <img src={item.cardImg} alt="avatar card" />
              </div>
              <span>{item.mappedCard}</span>
            </label>
          </li>
        ))}
        <li
          onClick={() => {
            setCardEdit('EDIT_CARD');
            setCardSelected(null);
            setValidate(true);
          }}
        >
          <input
            type="radio"
            id="EDIT_CARD"
            name="spend"
            value={cardName}
            checked={cardEdit === 'EDIT_CARD'}
            onChange={e => {}}
          />
          <label htmlFor="EDIT_CARD">
            <NameCardInput
              value={cardName}
              reset={!!cardSelected}
              validate={validate}
              onChange={onChange}
              setValidate={setValidate}
            />
          </label>
        </li>
      </ul>
      <div
        className={`card-validation__btn ${
          !cardSelected && cardName.length < 4 ? 'disabled' : ''
        }`}
        onClick={onConfirm}
      >
        Confirm
      </div>
    </div>
  );
}
