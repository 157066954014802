import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authencationFormSaga } from './saga';
import { HomeFormState } from './types';
import { getConnectPlaid } from 'utils/localStorages';

export const initialState: HomeFormState = {
  loading: false,
  plaidData: null,
  isPlaidLinkFail: false,
  isPlaidValidateData: !!getConnectPlaid(),
  plaidFailMessage: '',
  plaidFailId: null,
  rewardSummaryData: null,
  rewardSummaryMonthData: null,
  rewardEarnedData: null,
  rewardEarnedDataById: null,
  rewardMissedData: null,
  rewardMissedDetailsData: null,
  promptData: null,
  rewardRecommendedData: null,
  rewardRecommendedDetailsData: null,
  rewardRecommendedSuggestedData: null,
  rewardRecommendedCustomizesData: null,
  referralData: null,
  isRelink: false,
  cardsMappingFailed: null,
  cardsLinked: null,
  isLoadingRelink: false,
};

const slice = createSlice({
  name: 'homeForm',
  initialState,
  reducers: {
    getLinkPlaid(state) {
      state.loading = true;
    },
    getLinkPlaidSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.plaidData = payload;
      state.loading = false;
    },
    getReLinkPlaid(state, action: PayloadAction<any>) {
      state.loading = true;
      state.isPlaidLinkFail = false;
    },
    validatePlaid(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.plaidData = payload;
    },
    validatePlaidSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.isPlaidValidateData = !payload.success;
      state.loading = false;
      state.plaidFailMessage = null;
      state.plaidFailId = null;
    },
    validatePlaidFail(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.isPlaidLinkFail = !payload.success;
      state.plaidFailMessage = payload.message || '';
      state.plaidFailId = payload.idError || null;
    },
    updateReLinkPlaid(state, action: PayloadAction<any>) {
      state.loading = true;
      state.isPlaidLinkFail = false;
    },
    updateReLinkPlaidSuccess(state) {
      state.loading = false;
      state.plaidFailMessage = '';
      state.plaidFailId = null;
    },
    getPlaidLinkByUser(state, action: PayloadAction<any>) {
      // state.loading = true;
    },
    getRewardSummary(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getRewardSummarySuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.rewardSummaryData = payload;
      state.loading = false;
    },
    getRewardSummaryMonthSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.rewardSummaryMonthData = payload;
      state.loading = false;
    },
    getRewardEarned(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getRewardEarnedSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.rewardEarnedData = payload;
      state.loading = false;
    },
    getRewardEarnedById(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getRewardEarnedByIdSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.rewardEarnedDataById = payload;
      state.loading = false;
    },
    getRewardMissed(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getRewardMissedSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.rewardMissedData = payload;
      state.loading = false;
    },
    getRewardMissedDetails(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getRewardMissedDetailsSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.rewardMissedDetailsData = payload;
      state.loading = false;
    },
    addSpecialBonus(state, action: PayloadAction<any>) {},
    getRewardPrompt(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getRewardPromptSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.promptData = payload;
      state.loading = false;
    },
    answerRewardPrompt(state, action: PayloadAction<any>) {},
    skipRewardPrompt(state, action: PayloadAction<any>) {},
    updateAccount(state, action: PayloadAction<any>) {},
    getRecommended(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getRecommendedSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.rewardRecommendedData = payload;
      state.loading = false;
    },
    getRecommendedDetais(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getRecommendedDetaisSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.rewardRecommendedDetailsData = payload;
      state.loading = false;
    },
    getRecommendedSuggested(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getRecommendedSuggestedSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.rewardRecommendedSuggestedData = payload;
      state.loading = false;
    },

    getRecommendedCustomizes(state) {
      state.loading = true;
    },
    getRecommendedCustomizesSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.rewardRecommendedCustomizesData = payload;
      state.loading = false;
    },
    updateCustomizes(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    updateCustomizesSuccess(state) {
      state.loading = false;
    },
    getReferralData(state) {
      state.loading = true;
    },
    getReferralDataSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.referralData = payload;
      state.loading = false;
    },
    mappedFailedCard(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    // ----------------------------------------------------------------
    getCardsMappingFailed(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getCardsMappingFailedSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      const { payload } = action;
      state.cardsMappingFailed = payload;
    },
    getCardsLinked(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getCardsLinkedSuccess(state, action: PayloadAction<any>) {
      state.loading = true;
      const { payload } = action;
      state.cardsLinked = payload;
    },
    updateCardsMappingFailed(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    calculatedRewardsCardValidation(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    // add card
    getPlaidTokenByAccountAddCard(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getPlaidTokenByAccountAddCardSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.loading = false;
      state.plaidData = payload;
    },

    confirmAddCard(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    loadingRelinkEarned(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.isLoadingRelink = payload?.isLoad;
    },
  },
});

export const { actions: homeFormActions, reducer } = slice;

export const useHomeFormSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authencationFormSaga });
  return { actions: slice.actions };
};
