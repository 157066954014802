import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { myAccountFormSaga } from './saga';
import { MyAccountState } from './types';

export const initialState: MyAccountState = {
  loading: false,
  loadingReLink: false,
  isRedirect: false,
  linkedAccountData: null,
  plaidData: null,
  isPlaidValidateData: false,
  profile: null,
  notifications: null,
  helpQuestion: null,
  promptData: null,
  linkedAccountById: null,
};

const slice = createSlice({
  name: 'myAccountForm',
  initialState,
  reducers: {
    getLinkedAccount(state) {
      state.loading = true;
    },
    getReLinkPlaid(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getLinkedAccountSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.linkedAccountData = payload;
      state.loading = false;
    },
    getLinkPlaid(state) {
      state.loading = true;
    },
    getLinkPlaidSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.plaidData = payload;
      state.loading = false;
    },
    validatePlaid(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.plaidData = payload;
    },
    validatePlaidSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.isPlaidValidateData = !payload.success;
      state.loading = false;
    },
    updateReLinkPlaid(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getProfile(state) {
      state.loading = true;
    },
    getProfileSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.profile = payload;
      state.loading = false;
    },
    updateProfile(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getNotification(state) {
      state.loading = true;
    },
    getNotificationSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.notifications = payload;
      state.loading = false;
    },
    updateNotification(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    updateNotificationSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.notifications = payload;
      state.loading = false;
    },
    getHelpQuestion(state) {
      state.loading = true;
    },
    getHelpQuestionSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.helpQuestion = payload;
      state.loading = false;
    },
    postContactUs(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    skipRewardPrompt(state, action: PayloadAction<any>) {},
    answerRewardPrompt(state, action: PayloadAction<any>) {},
    getRewardPrompt(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getRewardPromptSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.promptData = payload;
      state.loading = false;
    },
    mappedFailedCard(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getPlaidLinkByUser(state, action: PayloadAction<any>) {
      // state.loading = true;
    },

    getPlaidTokenByAccount(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    getPlaidTokenByAccountSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.loading = false;
      state.plaidData = payload;
    },

    getLinkedAccountById(state, action: PayloadAction<any>) {
      state.loading = true;
    },

    getLinkedAccountByIdSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.linkedAccountById = action.payload;
    },

    confirmUnlink(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    unlinkSuccess(state) {
      state.loading = false;
    },

    confirmRelink(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    setLoadingRelink(state, action: PayloadAction<any>) {
      const { isLoad, isRedirect } = action.payload;
      console.log('action.payload?.isLoad: ', action.payload?.isLoad);
      state.loadingReLink = isLoad;
      state.isRedirect = isRedirect;
    },
  },
});

export const { actions: myAccountFormActions, reducer } = slice;

export const useMyAccountFormSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: myAccountFormSaga });
  return { actions: slice.actions };
};
