import { call, put, takeLatest } from 'redux-saga/effects';
import { isEmpty, some } from 'lodash';
import { homeFormActions as actions } from '.';
import {
  getLinkPlaid,
  validatePlaid,
  getRewardSummary,
  getPlaidByUser,
  getRewardEarned,
  getRewardEarnedById,
  addSpecialBonus,
  getRewardMissed,
  getRewardMissedDetails,
  getRewardPrompt,
  answerRewardPrompt,
  skipRewardPrompt,
  updateAccountUser,
  getRecommended,
  getRecommendedDetails,
  getRecommendedSuggestedDetails,
  getRecommendedCustomizesDetails,
  updateCustomizes,
  getReferralData,
  getReLinkPlaid,
  updateRelink,
  mappedCardFailed,
  getCardMappingFailed,
  getCardLinked,
  updateCard,
  calculateRewards,
  getTokenPlaid,
  addCardAccountPlaid,
} from 'api';
import {
  setConnectPlaid,
  setUserRegister,
  getUserRegister,
} from 'utils/localStorages';

export function* getLinkPlaidSaga() {
  try {
    const response = yield call(getLinkPlaid);
    if (response?.data) {
      yield put(actions.getLinkPlaidSuccess(response.data));
    }
  } catch (error) {}
}

export function* getReLinkPlaidSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(getReLinkPlaid, payload);
    if (response?.data) {
      yield put(actions.getLinkPlaidSuccess(response.data));
    }
  } catch (error) {}
}

export function* updateReLinkPlaidSaga(actionPayload) {
  const { payload } = actionPayload;
  const { cb } = payload;
  try {
    const response = yield call(updateRelink, payload);
    if (response?.data) {
      yield put(actions.updateReLinkPlaidSuccess());
      if (cb) {
        cb();
      }
    }
  } catch (error) {}
}

export function* validatePlaidSaga(actionPayload) {
  const { payload } = actionPayload;
  const { cb, ...rest } = payload || {};
  try {
    const response = yield call(validatePlaid, { payload: rest });
    if (response?.data?.status === 400) {
      yield put(
        actions.validatePlaidFail({
          success: false,
          message: response?.data?.response?.message,
          idError: response?.data?.response?.id,
        }),
      );
      if (cb) {
        cb({
          success: false,
          message: response?.data?.response?.message,
        });
      }
      return;
    } else if (response?.data) {
      yield put(actions.validatePlaidSuccess(response.data));
      if (cb) {
        cb({
          success: true,
          message: response?.data?.response?.message,
        });
      }
      setConnectPlaid('true');
    }
  } catch (error: any) {
    if (cb) {
      cb(false);
    }
    yield put(
      actions.validatePlaidFail({
        success: false,
        message: error?.response?.message,
        idError: error?.response?.id,
      }),
    );
  }
}

export function* getPlaidByUserSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(getPlaidByUser);
    const user = getUserRegister() || {};
    if (response?.data) {
      if (payload.cb) payload.cb({ success: true, data: response.data });
      setUserRegister(
        JSON.stringify({
          ...user,
          ...response.data,
          accountVerification: {
            ...user.accountVerification,
            ...response.data.accountVerification,
          },
        }),
      );
    } else {
      if (payload.cb) payload.cb({ success: false, data: null });
    }
  } catch (error) {
    if (payload.cb) payload.cb({ success: false, data: null });
  }
}

export function* getRewardSummarySaga(actionPayload) {
  const { payload } = actionPayload;
  const { cb, ...rest } = payload || {};
  try {
    const response = yield call(getRewardSummary, rest);
    if (response?.data) {
      if (rest.year && rest.month) {
        yield put(actions.getRewardSummaryMonthSuccess(response.data));
      } else yield put(actions.getRewardSummarySuccess(response.data));
      if (cb) cb(response.data);
    }
  } catch (error) {}
}

export function* getRewardEarnedSaga(actionPayload) {
  const { payload } = actionPayload;
  const { cb } = payload;
  try {
    const response = yield call(getRewardEarned, payload);

    if (response?.data?.data) {
      yield put(actions.getRewardEarnedSuccess(response.data.data));
      if (cb) {
        cb();
      }
    }
  } catch (error) {
    if (cb) {
      cb();
    }
  }
}

export function* getRewardEarnedByIdSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(getRewardEarnedById, payload);
    if (response?.data?.data) {
      yield put(actions.getRewardEarnedByIdSuccess(response.data.data));
    }
  } catch (error) {}
}

export function* addSpecialBonusSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(addSpecialBonus, payload);
    if (response?.data?.success && payload.cb) payload.cb();
  } catch (error) {}
}

export function* getRewardMissedSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(getRewardMissed, payload);
    if (response?.data?.data) {
      yield put(actions.getRewardMissedSuccess(response.data.data));
    }
  } catch (error) {}
}

export function* getRewardMissedDetailsSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(getRewardMissedDetails, payload);
    if (response?.data?.data) {
      yield put(actions.getRewardMissedDetailsSuccess(response.data.data));
    }
  } catch (error) {}
}

export function* getRewardPromptSaga(actionPayload) {
  const { payload } = actionPayload || {};
  const { cb } = payload || {};
  try {
    const response = yield call(getRewardPrompt);
    const data = response?.data?.data;
    if (data) {
      yield put(actions.getRewardPromptSuccess(data));
      if (cb && !isEmpty(data)) cb(true);
    }
  } catch (error) {}
}

export function* answerRewardPromptSaga(actionPayload) {
  const {
    payload: { cb, ...rest },
  } = actionPayload;
  try {
    const response = yield call(answerRewardPrompt, rest);
    if (response?.data?.success) {
      if (cb) cb({ success: true });
    }
  } catch (error) {}
}

export function* skipRewardPromptSaga(actionPayload) {
  const {
    payload: { cb, ...rest },
  } = actionPayload;
  try {
    const response = yield call(skipRewardPrompt, rest);
    if (response?.data?.success) {
      if (cb) cb({ success: true });
    }
  } catch (error) {}
}

export function* updateAccountSaga(actionPayload) {
  const {
    payload: { cb, ...rest },
  } = actionPayload;
  try {
    const response = yield call(updateAccountUser, rest);
    if (response?.data?.success) {
      if (cb) cb({ success: true });
    }
  } catch (error) {}
}

export function* getRecommendedSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(getRecommended, payload);
    if (response?.data?.data) {
      yield put(actions.getRecommendedSuccess(response.data.data));
    }
  } catch (error) {}
}

export function* getRecommendedDetailsSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(getRecommendedDetails, payload);
    if (response?.data?.data) {
      yield put(actions.getRecommendedDetaisSuccess(response.data.data));
    }
  } catch (error) {}
}

export function* getRecommendedSuggestedSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(getRecommendedSuggestedDetails, payload);
    if (response?.data?.data) {
      yield put(actions.getRecommendedSuggestedSuccess(response.data.data));
    }
  } catch (error) {}
}

export function* getRecommendedCustomizesSaga(actionPayload) {
  try {
    const response = yield call(getRecommendedCustomizesDetails);
    if (response?.data?.data) {
      yield put(actions.getRecommendedCustomizesSuccess(response.data.data));
    }
  } catch (error) {}
}

export function* updateCustomizesSaga(actionPayload) {
  const {
    payload: { cb, ...rest },
  } = actionPayload;
  try {
    const response = yield call(updateCustomizes, rest);
    if (response?.data?.success) {
      if (cb) cb({ success: true });
      yield put(actions.updateCustomizesSuccess());
    }
  } catch (error) {}
}

export function* getReferralDataSaga() {
  try {
    const response = yield call(getReferralData);
    if (response?.data) {
      yield put(actions.getReferralDataSuccess(response.data));
    }
  } catch (error) {}
}

export function* mappedFailedCardSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    yield call(mappedCardFailed, payload);
  } catch (error) {}
}

export function* getCardMappingFaildSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const { cb } = payload;
    const response = yield call(getCardMappingFailed);
    const data = response.data?.data || null;
    yield put(actions.getCardsMappingFailedSuccess(data));
    const checkNotValidated = some(data, { linkStatus: 'NOT_VALIDATED' });
    if (checkNotValidated) {
      if (cb) cb(true);
    } else {
      if (cb) cb(false);
    }
  } catch (error) {}
}

export function* getCardLinkedSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const { params, cb } = payload;
    const response = yield call(getCardLinked, params);
    const data = response?.data?.data;
    if (data) {
      yield put(actions.getCardsLinkedSuccess(data));
      cb(data);
    }
  } catch (error) {}
}

export function* updateCardMappingFailedSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const { params, cb } = payload;
    const response = yield call(updateCard, params);
    const data = response?.data?.data;
    if (data) {
      yield put(actions.getCardsMappingFailed({}));
      cb(data);
    }
  } catch (error) {}
}

export function* calculatedRewardsCardValidationSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const { cb } = payload;
    const response = yield call(calculateRewards);
    const success = response?.data?.success;
    if (success) {
      cb(success);
    }
    yield put(actions.getCardsMappingFailed({}));
  } catch (error) {}
}

export function* getPlaidTokenByAccountSaga(actionPayload) {
  const { payload } = actionPayload;
  const { cb, ...rest } = payload || {};
  try {
    const response = yield call(getTokenPlaid, rest);

    if (response?.data) {
      yield put(actions.getPlaidTokenByAccountAddCardSuccess(response?.data));
    }
    if (cb) cb();
  } catch (error) {}
}

export function* confirmAddCardPaidSaga(actionPayload) {
  const { payload } = actionPayload;
  const { cb, ...rest } = payload || {};
  try {
    const response = yield call(addCardAccountPlaid, { ...rest });
    const success = response.data.success || false;
    if (cb) cb(success);
  } catch (error) {
    if (cb) cb(false);
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* authencationFormSaga() {
  yield takeLatest(actions.getLinkPlaid.type, getLinkPlaidSaga);
  yield takeLatest(actions.getReLinkPlaid.type, getReLinkPlaidSaga);
  yield takeLatest(actions.updateReLinkPlaid.type, updateReLinkPlaidSaga);
  yield takeLatest(actions.validatePlaid.type, validatePlaidSaga);
  yield takeLatest(actions.getRewardSummary.type, getRewardSummarySaga);
  yield takeLatest(actions.getPlaidLinkByUser.type, getPlaidByUserSaga);
  yield takeLatest(actions.getRewardEarned.type, getRewardEarnedSaga);
  yield takeLatest(actions.getRewardEarnedById.type, getRewardEarnedByIdSaga);
  yield takeLatest(actions.addSpecialBonus.type, addSpecialBonusSaga);
  yield takeLatest(actions.getRewardMissed.type, getRewardMissedSaga);
  yield takeLatest(
    actions.getRewardMissedDetails.type,
    getRewardMissedDetailsSaga,
  );
  yield takeLatest(actions.getRewardPrompt.type, getRewardPromptSaga);
  yield takeLatest(actions.answerRewardPrompt.type, answerRewardPromptSaga);
  yield takeLatest(actions.updateAccount.type, updateAccountSaga);
  yield takeLatest(actions.skipRewardPrompt.type, skipRewardPromptSaga);
  yield takeLatest(actions.getRecommended.type, getRecommendedSaga);
  yield takeLatest(
    actions.getRecommendedDetais.type,
    getRecommendedDetailsSaga,
  );
  yield takeLatest(
    actions.getRecommendedSuggested.type,
    getRecommendedSuggestedSaga,
  );
  yield takeLatest(
    actions.getRecommendedCustomizes.type,
    getRecommendedCustomizesSaga,
  );
  yield takeLatest(actions.updateCustomizes.type, updateCustomizesSaga);
  yield takeLatest(actions.getReferralData.type, getReferralDataSaga);
  yield takeLatest(actions.mappedFailedCard.type, mappedFailedCardSaga);
  yield takeLatest(actions.getCardsMappingFailed.type, getCardMappingFaildSaga);
  yield takeLatest(actions.getCardsLinked.type, getCardLinkedSaga);
  yield takeLatest(
    actions.updateCardsMappingFailed.type,
    updateCardMappingFailedSaga,
  );
  yield takeLatest(
    actions.calculatedRewardsCardValidation.type,
    calculatedRewardsCardValidationSaga,
  );
  yield takeLatest(
    actions.getPlaidTokenByAccountAddCard.type,
    getPlaidTokenByAccountSaga,
  );
  yield takeLatest(actions.confirmAddCard.type, confirmAddCardPaidSaga);
}
