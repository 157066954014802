import React from 'react';
import { ModalBase } from './ModalBase';

export const ModalConfirmUnlink = props => {
  const { toggle, onSubmit, isOpen, card, listCards } = props;

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      className="modal-confirm-card-link"
    >
      <div className="modal-confirm-card-link__content d-flex flex-column justify-content-between">
        <div>
          <div className="modal-confirm-card-link__title">Unlink your card</div>
          <div className="modal-confirm-card-link__message">
            {`Do you want to unlink ${card?.mapped_card} from Uthrive?`}
          </div>
          {false ? (
            <>
              <div className="modal-confirm-card-link__note">
                {`Please note, all these accounts from ${card?.institutionName} will be unlinked.`}
              </div>
              <div className="modal-confirm-card-link__list">
                {listCards?.map(item => (
                  <div key={item.id} className="modal-confirm-card-link__card">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="card-inner d-flex justify-content-between align-items-center">
                        <div className="card-img">
                          {card?.cardImage ? (
                            <img
                              src={card?.cardImage || null}
                              alt="card-avatar"
                            />
                          ) : (
                            <div className="card-img border" />
                          )}
                        </div>
                        <span className="card-name">
                          {item?.accountName || 'null'}
                          {' - '}
                          {item?.accountMask || '123'}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="modal-confirm-card-link__card">
              <div className="d-flex justify-content-between align-items-center">
                <div className="card-inner d-flex justify-content-between align-items-center">
                  <div className="card-img border">
                    {card?.cardImage ? (
                      <img src={card?.cardImage} alt="card-avatar" />
                    ) : (
                      <div className="card-img border" />
                    )}
                  </div>
                  <span className="card-name">
                    {card?.institutionName || 'null'}
                    {' - '}
                    {card?.accountMask || '123'}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="modal-confirm-card-link__message">
          Please note, unlinking of an account is <strong>final</strong> and
          can’t be reversed. You may not be able to link this account again.
        </div>
        <div>
          <div className="modal-confirm-card-link__btn" onClick={onSubmit}>
            Confirm
          </div>
          <div className="modal-confirm-card-link__btn-back" onClick={toggle}>
            Back
          </div>
        </div>
      </div>
    </ModalBase>
  );
};
