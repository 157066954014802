import React, { useEffect, useState } from 'react';
import { linkDownLoadExtension, listIssue } from 'utils/constants';
import './styles.scss';
import logoUthrive from 'containers/Assets/images/Uthrive-logo-short-web.png';
import logo from 'containers/Assets/images/logo.svg';
import add from 'containers/Assets/images/add.png';
import { getLinkDownloadExtensionByBrowser } from 'utils/utils';
import { feedbackUninstallExtension, getShadowId } from 'api';
import { getUserRegister } from 'utils/localStorages';
// import { toast } from 'react-toastify';
import { Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const UninstallExtension = () => {
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [otherValue, setOtherValue] = useState('');
  const [user, setUser] = useState<any>({});
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const data = getUserRegister();
    setUser(data);
  }, []);

  const handleCheck = (value: string) => {
    if (checkedItems.includes(value)) {
      const temp = checkedItems.filter(e => e !== value);
      setCheckedItems(temp);
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };

  useEffect(() => {
    if (otherValue && !checkedItems.includes('other')) {
      setCheckedItems([...checkedItems, 'other']);
    }
  }, [otherValue]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const params = {
      options: checkedItems,
      comments: otherValue,
      shadowUserId: '',
    };
    if (user?.id) {
      params.shadowUserId = user.id;
    } else {
      const data = await getShadowId();
      params.shadowUserId = data?.data?.shadowUserId;
    }
    feedbackUninstallExtension(params)
      .then(res => {
        console.log(res);
        setCheckedItems([]);
        setOtherValue('');
        // toast.success('Successfully');
        setIsLoading(false);
        history.push('/uninstall-extension/thanks');
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <div className="uninstall-extension-wrapper">
      <div className="uninstall-extension-wrapper--header">
        <div className="uninstall-extension-wrapper--header-logo-wrapper">
          <img src={logo} alt="logo" />
        </div>
        <div className="uninstall-extension-wrapper--header-button">
          <a
            href={
              linkDownLoadExtension.find(
                e => e.key === getLinkDownloadExtensionByBrowser(),
              )?.value
            }
            target="_blank"
            rel="noreferrer"
          >
            <button>
              <img src={add} alt="icon" />
              Reinstall Uthrive
            </button>
          </a>
        </div>
      </div>
      <div className="uninstall-extension-wrapper--body">
        <div className="uninstall-extension-wrapper--body-title">
          <h1>Help us improve.</h1>
          <h5>Why did you uninstall Uthrive? Check all that apply.</h5>
        </div>
        <div className="uninstall-extension-wrapper--body-contents">
          <div className="uninstall-extension--body-logo">
            <div className="uninstall-extension--body-wrapper">
              <img src={logoUthrive} alt="logo" />
              <div className="uninstall-extension--body-logo-text">
                Did you know, on average consumers miss $200-$800 rewards and
                cashback by not using the right credit cards?
                <br />
                Get automatic advice on using your best card + much more.
              </div>
            </div>
          </div>
          <div className="uninstall-extension-wrapper--body-listItem">
            {listIssue.map(e => {
              return (
                <div className="checkbox-wrapper" key={e.value}>
                  <Input
                    addon
                    aria-label="Checkbox for following text input"
                    type="checkbox"
                    value={e.value}
                    onChange={() => {
                      handleCheck(e.value);
                    }}
                  />
                  <label>{e.label}</label>
                </div>
              );
            })}
            <textarea
              value={otherValue}
              onChange={e => setOtherValue(e.target.value)}
              placeholder="How else can we improve Uthrive?"
              className="uninstall-extension--text-area"
            />
            <button
              disabled={
                checkedItems?.length === 0 ||
                isLoading ||
                (checkedItems.includes('other') && otherValue === '')
              }
              onClick={handleSubmit}
              className="uninstall-extension--btn-submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UninstallExtension;
