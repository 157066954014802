import React from 'react';
import './styles.scss';

const Loader = ({ isConnecting, title }: any) => {
  if (!isConnecting) return null;
  return (
    <div className="container-loading">
      <div className="lds-title">{title || ''}</div>
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loader;
