import React, { useEffect, useState } from 'react';
import './styles.scss';
import logo from 'containers/Assets/images/logo.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { sendRatingEmail, updateRatingEmail } from 'api';
import { isMobile } from 'react-device-detect';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RatingApp = () => {
  const history = useHistory();
  let query = useQuery();

  const [textValue, setTextValue] = useState('');
  const [id, setId] = useState(null);

  useEffect(() => {
    const email = query.get('email');
    const notificationType = query.get('notificationType');
    const rate = parseInt(query.get('rate') || '0');

    const isValid = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(
      email || '',
    );

    if (!email || !rate || !notificationType) {
      history.replace('/rating/thanks');
      return;
    }

    if (rate > 5 || rate < 1) {
      history.replace('/rating/thanks');
      return;
    }
    const params = {
      emailAddress: email?.replaceAll(' ', '+'),
      rating: rate,
      feedback: '',
      notificationType: notificationType,
      deviceType: isMobile ? 'MOBILE' : 'DESKTOP',
    };
    sendRatingEmail(params)
      .then(res => {
        setId(res?.data?.data?.id);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleSubmit = async () => {
    const params = {
      feedback: textValue,
    };
    updateRatingEmail({ id, params })
      .then(() => {
        console.log('success');
      })
      .catch(err => {
        console.log(err);
      });
    history.replace('/rating/thanks');
  };
  return (
    <div className="ratings-page-wrapper-parent">
      <div className="ratings-page-wrapper">
        <div className="ratings-page-wrapper--body">
          <div className="ratings-page-wrapper--header">
            <div className="ratings-page-wrapper--header-logo-wrapper">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="ratings-page-wrapper--body-title">
            <h1>Thanks for your feedback.</h1>
            <h5>If you have additional comments, please share them here:</h5>
            {/* <h6>Your input helps us make our emails better.</h6> */}
          </div>
          <div className="ratings-page-input-wrapper">
            <textarea
              value={textValue}
              onChange={e => setTextValue(e.target.value)}
              placeholder="How else can we improve Uthrive?"
              className="ratings-page--text-area"
            />
          </div>
          <button
            disabled={!textValue}
            onClick={handleSubmit}
            className="ratings--btn-submit"
          >
            Submit
          </button>
          <div className="rating-footer">
            © Copyright 2021 Uthrive Inc. All rights reserved.{' '}
            <a
              href="https://www.uthrive.club/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RatingApp;
